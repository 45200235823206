.page-header {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 9rem;
    align-items: center;
    background-size: cover;
    background-color: $page-header-bg-color;

    @media (min-width: $breakpoint-lg) {
        min-height: 200px;
        align-items: center;
    }
}

.page-header__title {
    position: relative;
    text-align: center;
    margin: 1.5rem 20px 10px 20px;
    font-size: 2.6rem;

    @media (min-width: $breakpoint-lg) {
        margin: 1.5rem auto 10px auto;
        max-width: $constrain-max-width;
        width: 100%;
        text-align: center;
    }
}

.page-header__subtitle {
    margin: 0 20px 10px 20px;
    font-size: 1.4rem;
    position: relative;
    text-align: center;

    @media (min-width: $breakpoint-lg) {
        margin: 0 auto 10px auto;
        max-width: $constrain-max-width;
        width: 100%;
        text-align: center;
    }
}

.page-header__tinytitle {
    margin: 0;
    font-size: 1rem;
}

.page-header__category {
    text-align: center;
    position: relative;
    text-transform: uppercase;
    font-size: 0.8rem;
    margin: 10px 0 10px 0;
    font-weight: 600;

    @media (min-width: $breakpoint-lg) {
        margin: 10px auto 10px auto;
        max-width: $constrain-max-width;
        width: 100%;
        text-align: center;
    }
}

.page-header__date {
    text-align: center;
    position: relative;
    font-size: 0.8rem;
    margin: 10px 0 30px 0;

    @media (min-width: $breakpoint-lg) {
        margin: 10px auto 30px auto;
        max-width: $constrain-max-width;
        width: 100%;
        text-align: center;
    }
}

.page-header__breadcrumbs {
    position: relative;
    margin: 1.5rem;
}

.page-header__image {
    max-width: 100%;

    > img {
        max-width: 100%;
    }
}

.page-header--bg-image {
    @extend .page-header;

    &:before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }

    .page-header__title,
    .page-header__subtitle,
    .page-header__category,
    .page-header__breadcrumbs,
    .page-header__date {
        color: #fff;
        a {
            color: #fff;
        }
    }
}

.page-header--stamp {
    @extend .page-header;
    background-color: #fff;

    &:before {
        content: "";
        background-color: #000;
        height: 125px;
        width: 100%;
    }

    > img {
        filter: drop-shadow(0 0 5px #000);
        margin-top: -125px;
        z-index: 100;
    }

    @media (min-width: $breakpoint-lg) {
        &:before {
            height: 200px;
        }
    }
}

.page-header--big {
    @media (min-width: $breakpoint-lg) {
        align-items: center;
        min-height: 200px;

        .page-header__title {
            text-align: center;
            font-size: 3rem;
        }

        .page-header__category {
            text-align: center;
        }
    }
}

.page-header--home {
    @extend .page-header;
    align-items: center;

    .page-header__title {
        margin: 3rem 0;
        text-transform: uppercase;
        padding: 0 20px;
        max-width: 700px;
        text-align: center;

        @media (min-width: $breakpoint-lg) {
            margin: 6rem 0;
            font-size: 3.5rem;
            line-height: 4.5rem;
        }
    }
    .page-header__title,
    .page-header__subtitle {
        color: #fff;
    }
}

.page-header--individual {
    @extend .page-header;
    background-color: $area-individual-account-bg-color;
    color: #fff;
}

.page-header--volunteer {
    @extend .page-header;
    background-color: $area-individual-account-bg-color;
    color: #fff;
}

.page-header--exhibitor {
    @extend .page-header;
    background-color: $area-exhibitor-account-bg-color;
    color: #fff;
}

.page-header--error {
    @extend .page-header;
    background-color: $negative-bg-color;
}

.page-header--stand {
    @extend .page-header--bg-image;
    padding: 1rem 0;
    background-size: 0;

    &:before {
        filter: blur(1.5rem) opacity(0.2);
        background-image: inherit;
        background-size: cover;
        background-position: center center;
        z-index: 0;
        background-color: transparent;
    }

    > * {
        z-index: 10;
    }
}

.page-header__detail-list {
    position: relative;
    display: flex;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 0;
}

.page-header__detail {
    color: #fff;
    text-align: center;
    flex: 1;
    padding: 1rem 20px;

    @media (min-width: $breakpoint-lg) {
        margin: 15px 0;
        padding: 1rem 135px;
        display: flex;
        justify-content: center;
        text-align: left;
        align-items: center;
    }

    > div:nth-child(1) {
        font-size: 2rem;
        margin-bottom: 5px;
        margin-right: 15px;
    }

    h2 {
        font-size: 1.2rem;
        font-weight: 600;
        margin: 0;
    }

    &:nth-child(1) {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
    }
}

.page-header__countdown {
    position: relative;
    width: 100%;
    background-color: $header-countdown-bg-color;
    font-size: 1.5rem;
    padding: 1rem;
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
}

.page-header__stream {
    width: 100%;
    max-width: 1600px;
}

.page-header__bg-icon {
    position: absolute;
    top: -10%;
    height: 120%;
    transform: rotate(-15deg);
    opacity: 0.4;
}
