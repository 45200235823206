@media (min-width: $breakpoint-lg) {
  .product {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: minmax(min-content, max-content);
    width: 100%;
  }
  .product__image {
    grid-column-start: 1;
    grid-column-end: 1;
    grid-row-start: 1;
    grid-row-end: span 10;
    margin-right: 1.5rem;
  }

  .product__heading {
    grid-column-start: 2;
  }

  .product__description {
    grid-column-start: 2;
  }

  .product__actions {
    grid-column-start: 2;
  }
}

.product__description {
  > .layout__constrain {
    margin: 0 -30px;
  }
}

.product__delivery-note {
  margin: 20px 0 0 0;
  padding: 0 30px;
  position: relative;
  background: $negative-bg-color;
  display: flex;
  flex-direction: column;
}
