.nav-vertical ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.nav-vertical__item {
    > a,
    > form > button,
    > button {
        border: 0;
        background: transparent;
        text-decoration: none;
        padding: 10px 0 10px 0;
        color: $mobile-menu-fg-color;
        text-transform: uppercase;
        font-size: 1.4rem;
        display: block;
        position: relative;
        box-sizing: border-box;
        width: 100%;
        text-align: left;
        font-weight: bold;

        > svg {
            position: absolute;
            margin-left: 15px;
            top: 15px;
        }
    }
}

a.nav-switcher {
    border: 1px solid #000;
    border-width: 1px 0;
    padding: 1.5rem 40px 1.5rem 0;
    margin: 1.5rem 0;
    position: relative;
}

.nav-switcher__title {
    font-size: 1rem;
    display: block;
    text-transform: none;
}

.nav-switcher__subtitle {
    font-size: 0.8rem;
    display: block;
    font-weight: 300;
}

.nav-switcher__icon {
    position: absolute;
    display: flex;
    height: 32px;
    width: 32px;
    right: 0;
    top: 50%;
    padding-top: 3px;
    transform: translateY(-50%);
    border: 2px solid #000;
    border-radius: 100%;
    justify-content: center;
}
