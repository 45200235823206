.switch-multiple {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  > li {
    flex-grow: 1;
  }

  > li a {
    padding: 8px;
    display: block;
    background: $switch-inactive-bg-color;
    color: $switch-inactive-fg-color;
    text-align: center;
    overflow: hidden;
    margin-right: 2px;
    font-size: 0.8rem;
  }

  > li:first-child a {
    border-radius: $button-border-radius 0 0 $button-border-radius;
  }
  > li:last-child a {
    border-radius: 0 $button-border-radius $button-border-radius 0;
  }

  > li.is-active a {
    background: $switch-active-bg-color;
    color: $switch-active-fg-color;
  }
}
