.search-result a {
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: none;
  padding-bottom: 20px;
  display: block;

  > span {
    display: block;
    font-size: 0.9rem;
    font-weight: normal;
    color: #000;
  }
}
