/* Nav Mobile
-------------------------
Slide in mobile menus
*/

.nav-mobile {
  position: fixed;
  top: 60px;
  height: 100%;
  background-color: $mobile-menu-bg-color;
  width: 100%;
  z-index: 1200;
  transition: transform 0.2s ease-in-out;
  transform: translateX(100%);

  @media (min-width: $breakpoint-lg) {
    display: none;
  }

  &.is-active {
    transform: translateX(0);
  }
}

.nav-mobile__inner {
  height: calc(100% - 115px);
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;

  > ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  > ul > li ul {
    position: absolute;
    top: 0;
    background-color: $mobile-menu-bg-color;
    height: 100%;
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    transform: translateX(100%);
    transition: transform 0.2s ease-in-out;
    z-index: 100;

    &.is-active {
      transform: translateX(0%);
    }
  }
}

.nav-mobile__fixed {
  position: absolute;
  bottom: 60px;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: $mobile-menu-bg-color;
  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1);

  > ul {
    position: relative;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.nav-mobile__item {
  > a,
  > button {
    border: 0;
    background: transparent;
    text-decoration: none;
    padding: 15px 50px 15px 40px;
    color: $mobile-menu-fg-color;
    text-transform: uppercase;
    font-size: 1.2rem;
    display: block;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
    font-weight: bold;

    > svg {
      position: absolute;
      right: 40px;
      top: 25px;
    }
  }

  &--dropdown,
  &--up {
    @extend .nav-mobile__item;
    > a:after,
    > button:after {
      color: $mobile-menu-fg-color;
      position: absolute;
      margin: auto;
      border-style: solid;
      border-width: 0.15em 0.15em 0 0;
      content: "";
      height: 0.6em;
      vertical-align: top;
      width: 0.6em;
      transform: rotate(45deg);
      right: 20px;
      top: 0;
      bottom: 0;
      transition: all 0.2s ease-in-out;
    }
    &.is-unavailable a {
      color: #ccc;
    }
  }
  &--up {
    > a,
    > button {
      padding: 20px 50px 20px 80px;
      border-bottom: 1px solid $mobile-menu-divider-color;
      margin-bottom: 5px;
    }
    > a:after,
    > button:after {
      transform: rotate(-135deg);
      left: 40px;
      right: auto;
    }
  }

  &--bottom {
    @extend .nav-mobile__item;
    margin-top: auto;

    > a,
    > button {
      border-top: 1px solid $mobile-menu-divider-color;
    }
  }
}

.nav-mobile__button {
  padding: 15px 40px;
}

// User menu variant
.nav-mobile--user {
  @extend .nav-mobile;
  transform: translateX(-100%);
  background: $dark-bg-color;

  .nav-mobile__item {
    > a,
    > button {
      color: white;
      padding: 10px 50px 10px 40px;
    }
  }
  .nav-mobile__item--bottom {
    > a,
    > button {
      border-top: 1px solid $dark-divider-color;
    }
  }
}

.nav-mobile__account-status {
  margin-bottom: 20px;
  padding: 20px 50px 20px 40px;
  color: #fff;
  position: relative;
  border-bottom: 1px solid $dark-divider-color;
}

.nav-mobile__account-name {
  font-weight: 600;
}

.nav-mobile__account-type {
  text-transform: uppercase;
  font-size: 0.8rem;
  margin-top: 5px;
}

.nav-mobile__notification-badge {
  position: absolute;
  right: 50px;
  top: 25px;
  font-size: 1.6rem;
}

a.nav-mobile__item-switcher {
  background-color: $expo-yellow;
  color: black !important;
  padding: 1.5rem 50px !important;

  .nav-switcher__icon {
    right: 20px;
  }
}
