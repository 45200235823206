/* Layout
-------------------------
Common layout tools.
*/

.layout {
    width: 100%;
    margin: auto;
    position: relative;
    min-height: 100%;
    bottom: 0;
}

.layout__main {
    margin-top: 59px;

    @media (min-width: $breakpoint-lg) {
        margin-top: 0;
    }
}

.layout__bg--dark {
    background-color: #000;
    color: #fff;

    > p {
        color: #fff;
    }
    a {
        color: #fff;
        text-decoration: underline;
    }
    .button--primary,
    .button--secondary {
        color: #000;
        text-decoration: none;
    }
}

.layout__bg--subtle {
    background-color: $subtle-element-bg-color;
}

.layout__bg--positive {
    background-color: $positive-bg-color;
    color: #000;
}

.layout__constrain {
    max-width: $constrain-body-at;
    margin: 0 auto;
    padding: 0 $small-margins;
}

.layout__constrain-small {
    padding: 0 1.5rem;
    max-width: $constrain-small-at;
    margin-left: auto;
    margin-right: auto;
}

.layout__columns {
    max-width: $constrain-body-at;
    margin: 0 auto;

    @media (min-width: $breakpoint-lg) {
        display: flex;
    }
}

.layout__columns--tools {
    @media (min-width: $breakpoint-lg) {
        display: flex;
        max-width: $constrain-body-at;
        margin: auto;
        align-items: flex-start;
    }
}

.layout__column {
    flex: 1;
    padding: 0 $small-margins;

    @media (min-width: $breakpoint-lg) {
        padding: 1.5rem $small-margins;
    }

    &--slim {
        @extend .layout__column;
        border: 1px solid $subtle-element-bg-color;
        border-width: 1px 0;
        margin: 1.5rem 0;

        @media (min-width: $breakpoint-lg) {
            max-width: 350px;
            border: 0;
            margin: 0;
        }
    }
}

.layout__aside {
    background-color: $aside-bg-color;
    width: 100%;
    clear: both;
    margin-top: 4em;
}

.layout-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.layout__breadcrumbs {
    margin: 1.5rem 0;
}

.layout__notifications {
    position: absolute;
    top: 50px;
    width: 90%;
    z-index: 1200;
    right: 5%;

    @media (min-width: $breakpoint-lg) {
        right: 50px;
        width: 100%;
        max-width: 500px;
    }
}

.layout--v-space {
    margin-top: 3rem;
    margin-bottom: 3rem;
}
