.checkbox {
}

.checkbox input {
    @apply absolute top-auto overflow-hidden w-0 h-0 whitespace-nowrap;
}

.checkbox label {
    @apply relative py-1 pr-5 pr-0 flex cursor-pointer;

    > div {
        @apply flex;
    }

    &:before {
        content: "";
        @apply w-6 h-6 mr-2 border border-gray-400;
    }
}

.checkbox input:focus + label {
    @apply outline-dotted outline-gray-500;
}

.checkbox input:disabled + label {
    @apply opacity-80;
}

.checkbox input:checked + label:before {
    content: "\2713";
    @apply text-center border-2 border-gray-400;
}