.responsive-embed {
  position: relative;
  padding-bottom: 56.25%;

  &--twitch {
    padding-bottom: 99%;
    @media (min-width: $breakpoint-md) {
      padding-bottom: 47.5%;
    }
  }
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
