.react-select__control {
    display: flex;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    padding: 8px 8px;
    border-radius: 3px;
    border: 1px solid $form-input-border-inactive-color;
    border-radius: $form-border-radius;
    appearance: none;
    line-height: normal;

    &::placeholder {
        font-style: italic;
        color: $form-placeholder-fg-color;
    }

    &:focus {
        border: 1px solid $form-input-border-active-color;
        outline: none;
    }
    background: linear-gradient(#fff, #efefef);
}

.react-select__menu-list {
    > div {
        padding: 15px 8px;
    }
}
