.expo-map {
  background-color: #fff;
  height: 100vh;
}

.expo-map__wrapper {
  width: 100%;
  max-height: 900px;
  height: calc(100vh - 210px);
  overflow: hidden;
  position: fixed;
  z-index: 100;
  top: 60px;
  left: 0;

  @media (min-width: $breakpoint-lg) {
    position: relative;
    display: flex;
    top: 0;
    height: 100vh;
  }
}

.expo-map__map-wrapper {
  flex: 1;
  position: relative;
  height: 100%;
}

.expo-map-panel {
  height: 600px;
  position: fixed;
  z-index: 800;
  background-color: rgba(255, 255, 255, 0.95);
  height: 100%;
  width: 100%;
  border-radius: 20px 20px 0 0;
  border: 1px solid #ccc;
  touch-action: none;

  @media (min-width: $breakpoint-lg) {
    position: relative;
    height: auto;
    border-radius: 0;
    padding-top: 0;
  }
}

.expo-map-panel__handle {
  width: 100%;
  height: 40px;
  padding-top: 5px;
  transition: all 0.2s ease-in-out;

  &:before {
    content: "";
    position: relative;
    margin: auto;
    background-color: #ccc;
    width: 50px;
    transform: rotate(-10deg) translateX(-23px);
    transform-origin: top right;
    height: 6px;
    border-radius: 20px;
    display: block;
    transition: all 0.2s ease-in-out;
  }

  &:after {
    content: "";
    position: relative;
    margin: auto;
    background-color: #ccc;
    width: 50px;
    transform: rotate(10deg) translateX(23px) translateY(-6px);
    transform-origin: top left;
    height: 6px;
    border-radius: 20px;
    display: block;
    transition: all 0.2s ease-in-out;
  }

  &.is-active {
    padding-top: 20px;
    height: 25px;
    &:before {
      transform: rotate(10deg) translateX(-23px);
    }
    &:after {
      transform: rotate(-10deg) translateX(23px) translateY(-6px);
    }
  }
}

.expo-map-panel__wrapper {
  height: 100%;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-lg) {
    position: relative;
    width: 400px;
  }
}

.expo-map-panel__exhibitors {
  overflow: auto;
  scroll-behavior: smooth;

  > div:last-child {
    margin-bottom: 80vh;
    @media (min-width: $breakpoint-lg) {
      margin-bottom: 0;
    }
  }
}

.expo-map-panel__search {
  width: 100%;
  display: flex;
  align-items: center;

  > svg {
    width: 30px;
    height: 40px;
    color: $dove-gray;
    padding: 0 0 0 20px;
  }

  > input {
    padding: 20px;
    border: 0;
    background: transparent;
    flex: 1;
  }
}

.expo-map-list-item {
  align-items: center;
  padding: 10px 20px;
  border-bottom: 2px solid #eee;
  display: flex;

  &.is-active {
    background-color: $expo-yellow;
    border-bottom: 2px solid darken($expo-yellow, 10%);
  }
}

.expo-map-list-item__name {
  flex: 1;
  > span {
    display: block;
  }
}

.expo-map-list-item__button {
  background: $expo-yellow;
  border: 0;
  cursor: pointer;
  display: flex;
  padding: 8px;
  border-radius: 5px;
  margin-left: 10px;

  > svg {
    margin-left: 5px;
  }

  .is-active & {
    background: #fff;
  }
}

// Exhibitor Detail Panel

.expo-map-detail {
  padding: 20px;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.expo-map-detail__title {
  font-size: 1.1rem;
  margin: 0;
}

.expo-map-detail__logo {
  margin: 1rem 1rem 0.5rem 0;
  float: left;
}

.expo-map-detail__close {
  font-size: 1rem;
  display: flex;
  cursor: pointer;

  > svg {
    margin-right: 10px;
  }
}

.expo-map-detail__badges {
  margin: 0.5rem 0;
  display: flex;
  flex-wrap: wrap;
}

.expo-map-detail__embed {
  border: 0;
  flex: 1;
  width: 100%;
  margin-bottom: 200px;

  @media (min-width: $breakpoint-lg) {
    margin-bottom: 0;
  }
}

.leaflet-container {
  background-color: #fff !important;
}

// Map Switcher
.expo-map-switcher {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 900;
  width: 140px;
}

.expo-map-switcher__options {
  list-style: none;
  margin: -1px 0 0 0;
  padding: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.expo-map-switcher__item {
  padding: 15px;
  background: #fff;
  border: 0;
  width: 100%;
  display: flex;
  > div:nth-child(1) {
    flex: 1;
  }

  &--active {
    @extend .expo-map-switcher__item;
    border: 1px solid darken($expo-yellow, 10%);
    border-radius: 5px;
    font-weight: bold;

  }
}

// Loading Indicator
.expo-map-loading {
  background-color: #fff;
  color: #999;
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  height: 100%;
  z-index: 900;
}

.expo-map-loading__icon {
  font-size: 80px;
  position: relative;
  animation: shake-bottom 1s cubic-bezier(0.455, 0.03, 0.515, 0.955) infinite
    both;
}

@keyframes shake-bottom {
  0% {
    transform: rotate(4deg);
    -webkit-filter: blur(10px);
  }
  100% {
    -webkit-filter: blur(10px);
  }
  50% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
    -webkit-filter: blur(0);
  }
  60% {
    transform: rotate(2deg);
  }
  30%,
  10% {
    transform: rotate(-4deg);
  }
  20% {
    transform: rotate(4deg);
  }
  30% {
    transform: rotate(-2deg);
  }
  40% {
    transform: rotate(2deg);
  }
}
