.hijack-banner {
  background-color: #ffe761;
  color: #000;
  background-image: linear-gradient(
    135deg,
    rgba(0, 0, 0, 0.04) 25%,
    transparent 25%,
    transparent 50%,
    rgba(0, 0, 0, 0.04) 50%,
    rgba(0, 0, 0, 0.04) 75%,
    transparent 75%,
    transparent
  );
  display: flex;
  align-items: center;
  padding: 10px;
}

.hijack-banner__message {
  flex: 1;
  font-weight: bold;
}

.hijack-banner__actions button {
  padding: 10px;
  font-size: 1rem;
}
