.hex-stack {
  position: relative;
}

.hex-stack__icon {
  position: absolute;
  bottom: 0;
  left: 0;

  @media (min-width: $breakpoint-lg) {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
  }
}
