/* Button
-------------------------
Common button element with variants. Can be used on <a> or <button> elements.
*/

@keyframes bounce {
    to {
        transform: translateY(-5px);
    }
}

.button {
    border-radius: $button-border-radius;
    background: #fff;
    text-decoration: none;
    padding: 5px 10px;
    width: 100%;
    max-width: 300px;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    &:disabled {
        background-color: $button-inactive-bg-color;
        border-color: $button-inactive-bg-color;
        color: $button-inactive-fg-color;
        cursor: auto;
    }
    &:disabled:hover {
        background-color: $button-inactive-bg-color;
        color: $button-inactive-fg-color;
    }

    &.is-greyedout {
        background-color: $button-inactive-bg-color;
        color: $button-inactive-fg-color;
    }

    &.is-greyedout:hover {
        background-color: $button-inactive-bg-color;
        color: $button-inactive-fg-color;
    }

    &.is-loading {
        animation: bounce 0.5s infinite alternate;
    }

    &.is-complete {
        .button__state--pending {
            transform: translateY(-200%);
        }
        .button__state--complete {
            transform: translateY(0%);

            > div {
                transform: translateY(0%);
            }
        }
    }
}

.button__state {
    transition: all 0.3s ease-in-out;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    &--complete {
        transform: translateY(200%);
        position: absolute;

        > div {
            transition: all 0.3s ease-in-out;
            transform: translateY(-400%);
        }
    }
}

.button--primary {
    @extend .button;
    background-color: $button-primary-bg-color;
    color: $button-primary-fg-color;
    text-transform: uppercase;
    text-align: center;
    padding: 15px 20px;
    font-size: 1.3rem;
    font-weight: bold;

    &:hover {
        background-color: lighten($button-primary-bg-color, 10%);
    }
}

.button--primary-white {
    @extend .button--primary;
    background-color: white;
    color: #000;

    &:hover {
        background-color: lighten($button-primary-bg-color, 30%);
    }
}

.button--secondary {
    @extend .button;
    background-color: $button-secondary-bg-color;
    color: $button-secondary-fg-color;
    border: 3px solid $button-secondary-border-color;
    text-transform: uppercase;
    text-align: center;
    padding: 15px 10px;
    font-size: 1.3rem;
    font-weight: bold;

    &:hover {
        background-color: lighten($button-secondary-border-color, 30%);
    }
}

.button--tertiary {
    @extend .button;
    background-color: $button-tertiary-bg-color;
    color: $button-tertiary-fg-color;
    border: 2px solid $button-tertiary-border-color;
    text-align: center;
    padding: 15px 10px;
    font-size: 1.3rem;

    &:hover {
        background-color: lighten($button-tertiary-border-color, 40%);
    }
}

.button--negative {
    @extend .button;
    background-color: $button-negative-bg-color;
    color: $button-negative-fg-color;
    border: 2px solid $button-negative-border-color;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 10px;
    font-size: 1.3rem;
}

.button--sub-action {
    @extend .button;
    background-color: $button-sub-action-bg-color;
    color: $button-sub-action-fg-color;
    border-radius: 0;
    max-width: 100%;
    font-size: 1.3rem;
    padding: 20px 10px;
}

.button--subtle {
    @extend .button;
    background: transparent;
    color: #000;
    border-radius: 0;
    font-size: 1.3rem;
    padding: 15px 10px;
    text-transform: uppercase;
    font-weight: bold;
}

.button--likelink {
    background: transparent;
    border: 0;
    cursor: pointer;
    color: $link-text-color;
}

.button--stat {
    border: 1px solid #ccc;
    display: block;
    color: $base-text-color;
    font-weight: bold;
    text-align: center;
    padding: 1rem;

    > .button__label {
        text-transform: uppercase;
        display: block;
        font-weight: normal;
        font-size: 0.8rem;
    }

    &:hover {
        text-decoration: none;
    }

    &.is-active {
        background-color: $button-stat-active-bg-color;
        color: $button-stat-active-fg-color;
    }
}

// Big button with an icon for showing in a grid
.button--block {
    border: 1px solid #ccc;
    display: block;
    color: $base-text-color;
    font-weight: bold;
    text-align: center;
    padding: 1rem;

    > .button__label {
        display: block;
        font-weight: normal;
        font-size: 1.2rem;
        margin-top: 10px;
    }

    > svg {
        font-size: 2rem;
    }

    &:hover {
        text-decoration: none;
        background-color: $button-stat-active-bg-color;
        color: $button-stat-active-fg-color;
    }
}

/* Modifiers used in addition to main button class*/
.button--mini {
    font-size: 1.1rem;
    padding: 10px 20px;
}

.button--autosize {
    width: auto;
    max-width: 100%;
}

.button__icon {
    width: 50px;
    text-align: center;
}

.button__icon--right {
    @extend .button__icon;
    margin-left: auto;
}

.button__icon--inline {
    margin: 0 10px;
}

// List of buttons with spaces between them
.button-list {
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        margin-bottom: 10px;
    }
}
