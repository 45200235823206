.mod-hidden {
    display: none !important;
}

.mod-hide-children {
    > * {
        display: none;
    }

    > .is-active {
        display: block !important;
    }
}

.mod-space-between {
    > * {
        margin-bottom: 10px;
    }
}

.mod-sr-only {
    clip: rect(0 0 0 0);
    clip-path: inset(100%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.mod-order-first {
    order: -1;
}

.mod-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.mod-text-center {
    text-align: center;
}

.mod-margin-top {
    margin-top: 1.5rem !important;
}

.mod-margin-right {
    margin-right: 1.5rem !important;
}

.mod-margin-bottom {
    margin-bottom: 1.5rem;
}

.mod-v-pad {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.mod-h-pad {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.mod-v-margin {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
}

.mod-v-margin-sm {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
}

.mod-float-right {
    float: right;
}

.mod-invert-small-margins {
    margin-left: (0 - $small-margins);
    margin-right: (0 - $small-margins);
}

.mod-lg {
    display: none;

    @media (min-width: $breakpoint-lg) {
        display: block;
    }
}

.mod-sm {
    display: block;

    @media (min-width: $breakpoint-lg) {
        display: none;
    }
}

.mod-break {
    clear: both;
}

.mod-width-100 {
    min-width: 100px;
    > img {
        margin: auto;
    }
}
