.page-exhibitor {
  display: grid;
  grid-auto-rows: repeat(1fr);
  max-width: $constrain-body-at;
  margin: 0 auto;
  padding: 0 $small-margins;
  grid-column-gap: 2rem;
  grid-auto-flow: row dense;

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: 2fr 1fr;
  }
}

.page-exhibitor__header {
  grid-column: 1 / -1;
}

.page-exhibitor__about {
  grid-column: 1;
}

.page-exhibitor__map {
  background: $gallery;
  padding: 1rem 1.5rem;
  margin: 1rem 0;
  border-radius: 10px;
  position: relative;

  p {
      font-size: 1.2rem;
      margin-bottom: 1rem;
  }
}

.page-exhibitor__aside {
  @media (min-width: $breakpoint-lg) {
    grid-column: 2;
  }
}

.page-exhibitor__demo {
  background: lighten($gallery, 4%);
  padding: 1rem 1.5rem;
  margin: 1rem 0;
  border-radius: 10px;
  position: relative;

  > svg {
    font-size: 5rem;
    color: darken($gallery, 5%);
    position: absolute;
    right: 1.5rem;
    top: 1rem;
  }

  > h2 {
    z-index: 10;
    position: relative;
  }

  p {
    z-index: 10;
  }
}

.page-exhibitor__events {
  grid-column: 1 / -1;
}
