.slider {
  position: relative;
  &:before,
  &:after {
    content: "";
    left: 0;
    top: 0;
    width: 150px;
    height: 100%;
    position: absolute;
    pointer-events: none;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    z-index: 20;
  }

  &:after {
    right: 0;
    left: auto;
    pointer-events: none;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.glide__slide {
  text-align: center;
  transition: opacity 0.5s ease-in-out;
}

.glide__slide--fade {
  opacity: 0.3;
}
