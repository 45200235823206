.award-finalists {
    display: flex;
    flex-direction: column;
}

.award-finalists__category {
    text-transform: uppercase;
    margin: 1rem 0;
    text-align: center;

    @media (min-width: $breakpoint-lg) {
        text-align: left;
    }
}

.award-finalists__list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    flex: 1;

    > li {
        color: #999;
        font-size: 0.8rem;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        width: 100%;
    }

    > li > img {
        margin-right: 10px;
        margin-left: 0;
    }

    > li > span > span {
        color: #000;
        font-size: 1rem;
        display: block;
        width: 100%;
    }
}
