.card {
  margin: 0 $small-margins;
  text-align: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.card__image {
  max-width: 340px;
  text-align: center;
  margin: auto;

  > img {
    height: auto;
    margin: auto;
    max-width: 100%;
    display: block;
  }
}

.card__icon {
  font-size: 3rem;
  position: relative;
  width: 100px;
  height: 57.74px;
  background-color: $expo-yellow;
  color: #fff;
  margin: 28.87px 0;
  align-items: center;
  justify-content: center;
  align-self: center;

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    left: 0;
  }

  &:before {
    bottom: 100%;
    border-bottom: 28.87px solid $expo-yellow;
  }

  &:after {
    top: 100%;
    width: 0;
    border-top: 28.87px solid $expo-yellow;
  }
}

.card__title {
  font-size: 1.5rem;
  text-align: center;
  margin: 1rem 0;
  word-wrap: break-word;

  > a {
    color: black;
    text-decoration: none;
  }
}

.card__text {
  color: $secondary-information-fg-color;
  text-align: center;
  font-size: 1rem;
  flex: 1;

  .rich-text p {
    margin: 0 0 1rem 0;
  }
}

.card__action {
  font-size: 1.2rem;
  margin: 1rem 0;
}

.card--fact {
  @extend .card;
}

.card--page {
  @extend .card;

  .card__title {
    font-size: 1.9rem;
    margin: 2rem 0 1rem 0;
    border-radius: 10px;
  }

  .card__text {
    font-size: 1.2rem;
  }
}

.card--section {
  @extend .card;
  margin: 0 auto;
  max-width: 410px;

  .card__image {
    position: relative;
    max-width: 100%;
  }

  .card__image:after {
    content: "";
    background-color: #000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
  }

  .card__title {
    color: #fff;
    z-index: 10;
    font-size: 1.6rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: center;
  }

  .card__text {
    background-color: $subtle-element-bg-color;
    padding: 1.5rem 1rem;
    text-align: left;
    font-size: 1.2rem;
  }
}

.card--black {
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: #fff;
  border-radius: $button-border-radius;
  padding: 1.5rem;
  align-items: center;
  text-align: center;
  position: relative;
  background-size: 0;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: #000;
    width: 100%;
    height: 100%;
    border-radius: $button-border-radius;
    z-index: 0;
  }

  &:after {
    background-image: inherit;
    opacity: 0.4;
    filter: blur(4px);
    background-size: cover;
    background-position: center center;
  }

  .card__image > img {
    height: 100px;
    width: auto;
    z-index: 10;
    position: relative;
  }

  .card__title {
    font-size: 1.5rem;
    margin: 1.5rem 0 0 0;
    z-index: 10;
    position: relative;
  }

  .card__text {
    margin: 1.5rem 0;
    flex: 1;
    z-index: 10;
    position: relative;
    color: #fff;

    p,
    a {
      color: #fff;
    }
    a {
      text-decoration: underline;
    }
  }

  .card__action {
    margin: 0;
    z-index: 10;
    position: relative;
  }
}
