.block-image {
  margin: 1.5rem (0px - $small-margins);
  text-align: center;
  img {
    max-width: 100%;
  }
}

.block-image--left {
  @extend .block-image;

  @media (min-width: $breakpoint-lg) {
    float: left;
    max-width: 50%;
    margin: 1.5rem 20px 0 0;
  }
}

.block-image--right {
  @extend .block-image;

  @media (min-width: $breakpoint-lg) {
    float: right;
    max-width: 50%;
    margin: 1.5rem 0 0 20px;
  }
}

.block-image--center {
  @extend .block-image;
  text-align: center;

  @media (min-width: $breakpoint-lg) {
    > img {
      width: auto;
    }
  }
}
