/* Form
-------------------------
Form component for consistent styling of form elements
*/

.form__fieldset {
    padding: 0;
    border: none;
    margin: auto;

    legend {
        font-size: 1.2rem;
        width: 100%;
    }
}

.form__errors {
    font-size: 0.9rem;
    border-radius: $form-border-radius;
    background-color: $form-error-bg-color;
    color: $form-error-fg-color;
    padding: 10px;
    margin-top: 20px;

    > ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}

.form__buttons {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    width: 100%;

    > button,
    a {
        margin: 0 0.5rem;
    }
}

// Group field elements together when we need to show more than one
// in a row, etc.
.form__group {
    display: flex;
    flex-wrap: wrap;

    &--inline {
        flex: 1;
        display: flex;
        flex-wrap: wrap;

        &:not(:nth-child(1)) {
            margin-left: 20px;
        }
    }

    .form__field:not(:nth-child(1)) {
        margin-left: 20px;
    }
}

// Wraps a form label, input and all other elements related to that field
.form__field {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 10px 0;

    &.is-disabled {
        opacity: 0.5;
    }
}

// Field label and required star styling
.form__label {
    font-size: 1.1rem;
    padding: 15px 0 10px 0;
    color: $form-label-fg-color;

    > span {
        color: $form-required-label-color;
        font-weight: bold;
    }
}

.form__sublabel {
    font-size: 0.9rem;
    padding: 0 0 10px 0;
    color: lighten($form-label-fg-color, 20%);
}

// Prefix that appears before a field
.form__prefix {
    position: absolute;
    top: auto;
    bottom: auto;
    left: 15px;
}

// Help text which can be shown below the field
.form__help-text {
    background-color: $secondary-information-bg-color;
    color: $secondary-information-fg-color;
    font-size: 0.9rem;
    border-radius: $form-border-radius;
    padding: 0;
    position: relative;
    transition: all 0.2s ease-in-out;
    max-height: 0;
    overflow: hidden;
    z-index: -3;
    padding: 0 10px;
    margin-top: -15px;
    opacity: 1;
    max-height: 200px;
    padding: 25px 10px 10px 10px;

    &--fixed {
        @extend .form__help-text;
        max-height: 200px;
        padding: 10px 0;
        margin-top: 0;
        opacity: 1;
        transform: translateY(0);
        background: transparent;
    }
}

.form__clear-button {
    position: absolute;
    background-color: transparent;
    border: 0;
    color: $form-help-button-bg-color;
    right: 5px;
    top: 0;
    bottom: 0;
    font-size: 24px;
    line-height: 0;
    text-align: center;
    display: flex;
    align-items: center;
    cursor: pointer;
}

// Additional notes on the form field
.form__field-note {
    font-style: italic;
    font-size: 0.8rem;
    color: $form-label-fg-color;
    margin-top: 5px;
    width: 100%;
}

// Additional data in a form's label (for checkboxes/radio buttons, mainly)
.form__field-label-note {
    display: block;
    font-size: 0.9rem;
    color: $form-label-fg-color;
}

// Error styling if a specific field has an error
.form__field-error {
    font-size: 0.9rem;
    border-radius: $form-border-radius;
    background-color: $form-error-bg-color;
    color: $form-error-fg-color;
    padding: 25px 10px 10px 10px;
    margin-top: -15px;
    z-index: -2;

    &--fixed {
        @extend .form__field-error;
        padding: 0;
        margin-top: 0;
        opacity: 1;
        background: transparent;
    }
}

.form__image {
    margin-bottom: 20px;
}

.form__input {
    position: relative;
    line-height: 0;
    color: $form-label-fg-color;
    display: flex;
    align-items: center;

    > input,
    > select,
    > textarea {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding: 15px 10px;
        border-radius: 3px;
        border: 1px solid $form-input-border-inactive-color;
        border-radius: $form-border-radius;
        appearance: none;
        line-height: normal;

        &::placeholder {
            font-style: italic;
            color: $form-placeholder-fg-color;
        }

        &:focus {
            border: 1px solid $form-input-border-active-color;
            outline: none;
        }
    }

    > select {
        background: linear-gradient(#fff, #efefef);
    }

    &--short {
        > input {
            width: auto;
        }
    }
}

.form__input--select {
    &:after {
        position: absolute;
        pointer-events: none;
        content: "";
        width: 0;
        height: 0;
        right: 23px;
        top: 0;
        bottom: 0;
        margin: auto;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 9px solid $form-help-button-bg-color;
        border-bottom: 0;
    }
}

.form__input--smart-select {
    > div {
        width: 100%;
    }
}

.form__input--numeric {
    display: flex;
    flex-direction: row;

    > input {
        width: 50px;
        appearance: textfield;
        border: 0;
        text-align: center;
        font-weight: bold;
        background: transparent;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    > button {
        border-radius: 100%;
        border: 1px solid $dark-divider-color;
        color: $dark-divider-color;
        background: #fff;
        height: 2rem;
        width: 2rem;
        line-height: 2rem;
        text-align: center;
        cursor: pointer;
        padding: 0;

        &:disabled {
            opacity: 0.3;
            cursor: default;
        }
    }
}

.form__input--radio,
.form__input--checkboxes {
    line-height: inherit;
    > ul,
    > div {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    input {
        position: absolute;
        top: auto;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        width: 1px;
        height: 1px;
        white-space: nowrap;
    }

    label {
        position: relative;
        padding: 5px 10px 5px 0;
        display: flex;

        > img {
            margin-right: 10px;
        }

        > div {
            flex: 1;
        }

        &:before {
            content: "";
            width: 20px;
            height: 20px;
            margin-right: 9px;
            background-color: #fff;
        }
    }
    > ul > li > div {
        padding: 5px 10px 5px 0;
    }

    input:focus + label {
        outline: 1px dotted $form-input-border-inactive-color;
    }

    input:disabled + label {
        opacity: 0.8;
    }
}

.form__input--radio > ul > li,
.form__input--radio > div > div {
    > label {
        &:before {
            border-radius: 100%;
            width: 20px;
            height: 20px;
            border: 4px solid #fff;
            box-shadow: 0 1px 0 0 $form-input-border-inactive-color,
                0 -1px 0 0 $form-input-border-inactive-color,
                -1px 0 0 0 $form-input-border-inactive-color,
                1px 0 0 0 $form-input-border-inactive-color,
                0 0 0 1px $form-input-border-inactive-color;
        }
    }

    > input:checked + label:before {
        background-color: $form-input-checked-fg-color;
        box-shadow: 0 2px 0 0 $form-input-border-active-color,
            0 -2px 0 0 $form-input-border-active-color,
            -2px 0 0 0 $form-input-border-active-color,
            2px 0 0 0 $form-input-border-active-color,
            0 0 0 2px $form-input-border-active-color;
    }

    > input:disabled + label {
        opacity: 0.8;
    }

    > input:disabled:checked + label {
        opacity: 1;
    }
}

.form__input--checkboxes {
    label {
        &:before {
            width: 23px;
            height: 23px;
            border: 1px solid $form-input-border-inactive-color;
        }
    }

    input:checked + label:before {
        content: "\2713";
        text-align: center;
        border: 2px solid $form-input-border-active-color;
    }
}

.form__input--card {
    padding: 15px 10px;
    border-radius: $form-border-radius;
    border: 1px solid $form-input-border-inactive-color;
}

.form__input--richtext {
    line-height: auto;
    .ck-editor__editable_inline {
        min-height: 100px;
    }
}

.form__input--code {
    @extend .form__input;
    input {
        text-align: center;
        letter-spacing: 30px;
        font-size: 1.5rem;
        text-indent: 15px;
    }
    input::placeholder {
        font-style: normal;
        color: $form-placeholder-fg-color;
        text-align: center;
    }
}

.form__input--prefixed {
    @extend .form__input;
    input {
        text-indent: 20px;
    }
}

.form__accordion {
    .form__input {
        display: none;
    }

    .form__field.is-active .form__input {
        display: block;
    }
}

.form__meta-value {
    margin-left: auto;
    align-self: center;
}

// Repeatables
.form-repeatable ol {
    counter-reset: type-list;
    list-style: none;
    padding: 0;

    > li {
        counter-increment: type-list;
        padding-left: 25px;
        position: relative;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 1rem 0 1rem 0;
        color: $base-text-color;
        display: flex;

        &:before {
            content: counter(type-list);
            font-weight: bold;
            font-size: 1.2rem;
            position: absolute;
            top: 25px;
            left: 0;
            color: $typography-highlighted-element-color;
        }
    }
}
.form-repeatable__body {
    flex: 1;
}

.form-repeatable__delete {
    background: transparent;
    border: 0;
    color: $button-negative-fg-color;
    cursor: pointer;
    width: 100px;
    padding: 30px 0;
    align-self: flex-end;

    &.is-hidden {
        visibility: hidden;
    }
}

.form-repeatable__add {
    background: #fff;
    text-decoration: none;
    padding: 10px 10px;
    width: 100%;
    max-width: 300px;
    border: 0;
    box-sizing: border-box;
    cursor: pointer;
    line-height: 1em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;

    > svg {
        margin-right: 10px;
    }
}

.form__input input[type="file"] {
    background-color: #fff;
}
