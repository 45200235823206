.block-text {
  padding: 1px;
}

.block-text--intro {
  @extend .block-text;
  text-align: center;
  max-width: $constrain-small-at;
  margin: auto;

  p {
    font-size: 1.1rem;
    line-height: 1.7rem;
  }

  > svg {
    font-size: 6rem;
  }
}

.block-text--standard {
  @extend .block-text;
}
