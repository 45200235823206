/* Header
-------------------------
Page header structure
*/

.header {
    position: relative;
    border-bottom: 1px solid $header-border-color;
}

.header__top {
    display: none;
    border-bottom: 1px solid #eee;
    padding: 10px 135px;
    z-index: 20;
    position: relative;
    background: #000;
    color: #fff;
    align-items: center;

    @media (min-width: $breakpoint-lg) and (max-width: $constrain-max-width) {
        display: flex;
        padding: 10px;
    }

    @media (min-width: $constrain-max-width) {
        display: flex;
        padding: 10px calc((100vw - #{$constrain-max-width}) / 2);
    }
}

.header__date {
    flex: 1;
}

.header__account {
    margin-left: 10px;
    > a {
        color: #fff;
        text-decoration: none;
        border-radius: $button-border-radius;
        border: 2px solid #fff;
        padding: 4px 10px;
        box-sizing: border-box;
        min-width: 180px;
        display: block;
        text-align: center;

        &:hover {
            background-color: #555;
        }
    }
}

.header__actions {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    > a {
        min-width: 190px;
        font-size: 1rem;
        padding: 10px 10px;
        margin-left: 20px;
    }
}

.header__main {
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 1100;
    position: fixed;
    width: 100%;
    background: linear-gradient(
        180deg,
        #000000 0%,
        #323232 94.27%,
        #000000 100%
    );
    box-sizing: border-box;

    @media (min-width: $breakpoint-lg) {
        margin: auto;
        max-width: $constrain-max-width;
        position: relative;
        background: #fff;
    }

    @media (min-width: $breakpoint-lg) and (max-width: $constrain-max-width) {
        padding: 0 10px;
    }
}

.header__user-badge {
    background: transparent;
    border: 0;
    display: block;
    cursor: pointer;
    position: relative;
    width: 80px;
    height: 40px;
    margin: auto auto auto 20px;
    z-index: 100;
    outline: 0;
    font-size: 2rem;
    line-height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $expo-yellow;
    text-align: left;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    svg {
        transition: all 0.2s ease-in-out;
        margin-right: auto;
        margin-left: 0;
        height: 0.8em;
    }

    @media (min-width: $breakpoint-lg) {
        display: none;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        left: 0;
        display: block;
        opacity: 0;
        width: 30px;
        height: 3px;
        background-color: #000;
        transition: all 0.2s ease-in-out;
        transform-origin: center;
    }

    &.is-active {
        svg {
            opacity: 0;
        }
        &:before,
        &:after {
            opacity: 1;
        }
        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}

.header__logo {
    margin: 10px;
    display: block;
    flex: 1;
    text-align: center;
    display: none;

    > img {
        display: block;
        height: 40px;
        margin: auto;
        max-width: initial;
    }

    @media (min-width: $breakpoint-lg) {
        display: block;
        flex: 0;
        margin: 20px 30px 20px 0;
        > img {
            height: 70px;
        }
    }
}

.header__logo--mobile {
    @extend .header__logo;
    display: block;
    @media (min-width: $breakpoint-lg) {
        display: none;
    }
}

.header__menu-toggle {
    background: transparent;
    border: 0;
    display: block;
    cursor: pointer;
    position: relative;
    width: 80px;
    height: 40px;
    margin: auto 20px auto auto;
    z-index: 100;
    top: -2px;
    outline: 0;
    padding: 0;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    > .header__menu-toggle-icon {
        height: 16px;
        position: relative;
        width: 30px;
        margin-left: auto;
    }

    .header__basket-count {
        right: -10px;
        top: -15px;
    }

    &.is-active {
        span:nth-child(1) {
            opacity: 0;
        }
        span:nth-child(2) {
            top: 7px;
            transform: rotate(45deg);
        }
        span:nth-child(3) {
            opacity: 0;
        }
        span:nth-child(4) {
            top: 7px;
            transform: rotate(-45deg);
            opacity: 1;
        }
    }

    span {
        position: absolute;
        left: 0;
        display: block;
        width: 100%;
        height: 3px;
        background-color: $expo-yellow;
        transition: all 0.2s ease-in-out;
        transform-origin: center;
    }

    span:nth-child(1) {
        top: 0;
    }
    span:nth-child(2) {
        top: 50%;
    }
    span:nth-child(3) {
        top: 100%;
    }
    span:nth-child(4) {
        top: 50%;
        opacity: 0;
    }

    @media (min-width: $breakpoint-lg) {
        display: none;
        span {
            background-color: #000;
        }
    }
}

.header__menu {
    display: none;
    align-items: center;

    @media (min-width: $breakpoint-lg) {
        display: flex;
    }
}

.header__tools {
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;
    font-size: 1.3rem;
    align-items: center;
    margin-left: auto;

    > li {
        position: relative;
    }

    > li > a {
        padding: 20px;
        color: #000;
    }

    @media (min-width: $breakpoint-lg) {
        display: flex;
    }
}

.header__basket-count {
    background-color: $unread-notification-bg;
    color: #fff;
    text-align: center;
    border-radius: 100%;
    font-size: 0.9rem;
    line-height: 1.2rem;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -5px;
    right: 5px;
    font-weight: bold;
    border: 2px solid #fff;
    pointer-events: none;
    display: none;
}

.header__basket-popup {
    position: absolute;
    top: 71px;
    right: -50px;
    width: 300px;
    z-index: 900;
    display: none;

    border-top: 3px solid darken($subtle-element-bg-color, 10%);
    background: $subtle-element-bg-color;

    &:before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        right: 50px;
        margin: auto;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 25px solid darken($subtle-element-bg-color, 10%);
        border-top: 0;
        top: -25px;
        transform: rotate(0deg);
        transition: all 0.2s ease-in-out;
        z-index: 20;
    }

    &.is-active {
        display: block;
    }
}
