.card-grid {
  clear: both;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: $small-margins;

  @media (min-width: $breakpoint-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 0;
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 0;
  }
}

.card-grid--small {
  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
    margin: 0;
  }

  > div {
    margin: 0 5px;
  }
}

.card-grid--medium {
  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    margin: 0;
  }

  > div {
    margin: 0 5px;
  }
}

.card-grid--large {
  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin: 0;
  }
  > div {
    margin: 0 5px;
  }
}

.card-grid--very-large {
  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin: 0;
  }
  > div {
    margin: 0 5px;
  }
}

.card-grid--flex {
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1;
    min-width: 200px;
  }
}

.card-grid--left {
  .card__text,
  .card__action,
  .card__title {
    text-align: left;
  }
}
