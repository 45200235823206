.checkbox-grid {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 50px;
        height: 100%;
        pointer-events: none;
        background: linear-gradient(
            to right,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 100%
        );
        z-index: 20;
    }
}

.checkbox-grid__inner {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.checkbox-grid__table {
    width: min-content;
    position: relative;
    padding: 0 60px 0 30px;
    box-sizing: border-box;
}

.checkbox-grid__row {
    width: min-content;
    flex-wrap: nowrap !important;
}

.checkbox-grid__col {
    padding: 0;
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    color: #999;

    &.is-active {
        background: $expo-yellow !important;
        border-color: $expo-yellow !important;
        color: #fff;
    }
}

.checkbox-grid__count {
    font-size: 12px;
    font-weight: bold;
    flex: 1;
}

.checkbox-grid__cell-name {
    font-size: 10px;
    font-weight: bold;
    line-height: 12px;
}

.checkbox-grid__row:nth-child(even) {
    .checkbox-grid__col:nth-child(even) {
        background: #f7f7f7;
    }
}

.checkbox-grid__row:nth-child(odd) {
    .checkbox-grid__col:nth-child(odd) {
        background: #f7f7f7;
    }
}

.checkbox-grid__label {
    background: #eee;
    text-align: center;
    min-height: 30px;
    color: $base-text-color;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.8rem;

    > span svg {
        font-size: 0.8rem;
        padding: 0 10px;
    }

    &--vertical-left,
    &--vertical-right {
        @extend .checkbox-grid__label;
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        min-width: 30px;

        > span {
            text-orientation: mixed;
            writing-mode: vertical-rl;
        }
    }
    &--vertical-right {
        right: 30px;
    }

    &--vertical-left {
        right: auto;
        left: 0;

        > span {
            transform: rotate(180deg);
        }
    }
}
