.paginator {
  display: flex;
  margin: 20px 0;
  padding: 0;
  list-style: none;
  justify-content: center;
  align-items: center;
}

.paginator__page {
  > a {
    padding: 4px 6px;
    text-align: center;
    border: 1px solid $paginator-border-color;
    text-decoration: none;
    color: $paginator-fg-color;
    display: block;
    margin: 5px;
    background: $paginator-inactive-bg-color;
    font-size: 0.9rem;
    line-height: 0.9rem;
  }

  &.is-active a {
    background: $paginator-active-bg-color;
  }
}
