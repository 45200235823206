/* Pay What You Want Widget */

.pwyw {
  display: flex;
  margin: 1rem 0;
}

.pwyw__prompt {
  font-weight: bold;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.pwyw__text {
  flex: 1;
  margin-right: 20px;
}

.pwyw__fields {
  flex: 1;

  > button {
    max-width: 100%;
  }
}

.pwyw__options {
  margin-bottom: 10px;

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
  }

  > ul li {
    flex: 1;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.pwyw__options label {
  background-color: #fff;
  border-radius: $button-border-radius;
  padding: 10px;
  display: block;
  text-align: center;
  font-weight: bold;
  border: 1px solid #fff;
  cursor: pointer;
}

.pwyw__options input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  &:focus + label {
    border: 1px solid $active-state-bg;
  }
  &:checked + label {
    background-color: $active-state-bg;
    border: 1px solid $active-state-bg;
    color: #fff;
  }
}

.pwyw__other {
  margin-bottom: 10px;
}
