.basket__items {
  list-style: none;
  margin: 0;
  padding: 0;
}

.basket__item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid darken($subtle-element-bg-color, 5%);
}

.basket__item-name {
  margin: 0 20px 0 0;
  width: 100%;

  @media (min-width: $breakpoint-lg) {
    flex: 1;
    width: auto;
    margin-bottom: 0;
  }
}

.basket__item-description {
  margin: 0;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 0.8rem;

  @media (min-width: $breakpoint-lg) {
    margin-bottom: 0;
  }
}

.basket__item-quantity {
  align-items: flex-start;
  margin-right: 2rem;
  flex: 1;

  input {
    font-weight: 500;
    display: block;
    font-size: 1.2rem;
    min-width: 40px;
    text-align: center;
  }
}

.basket__item-price {
  font-size: 1.2rem;
  font-weight: 500;
  min-width: 100px;
  text-align: right;
  margin-left: auto;
}

.basket__quantity-button {
  border-radius: 100%;
  border: 1px solid $dark-divider-color;
  color: $dark-divider-color;
  background: #fff;
  height: 2rem;
  width: 2rem;
  line-height: 2rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
}

.basket__item-remove {
  border: 0;
  background: transparent;
  position: absolute;
  top: 1rem;
  right: 0;
  cursor: pointer;

  @media (min-width: $breakpoint-lg) {
    position: relative;
    width: 2rem;
    height: 2rem;
    top: 0;
  }
}

.basket__totals {
  margin: 1.5rem 0;
  padding: 0.5rem 1rem;
  width: 100%;
  box-sizing: border-box;
  background: lighten($subtle-element-bg-color, 5%);
  border: 1px solid $subtle-element-bg-color;

  @media (min-width: $breakpoint-lg) {
    margin-right: 2rem;
  }
}

.basket__total {
  display: flex;
  padding: 0.5rem 0;
}

.basket__total-label {
  flex: 1;
  text-align: left;
}

.basket__total-value {
  text-align: right;
  text-transform: uppercase;
}

.basket__total--final {
  @extend .basket__total;
  font-weight: 500;
}

.basket__buttons {
  display: flex;
  justify-content: center;
}

.basket--small {
  .basket__item {
    margin: 1rem 0;
    padding: 0 1rem 1rem 1rem;
    border-bottom: 2px solid darken($subtle-element-bg-color, 5%);
  }
  .basket__item-name {
    font-size: 1rem;
    width: 100%;
    flex: auto;
    margin-bottom: 0.5rem;
  }

  .basket__item-quantity {
    flex: 1;
  }

  .basket__item-quantity span,
  .basket__item-price {
    font-size: 1rem;
  }
  .basket__item-remove {
    border: 0;
    background: transparent;
    position: absolute;
    top: -0.6rem;
    right: 0.4rem;
  }
  .basket__quantity-button {
    height: 1.2rem;
    width: 1.2rem;
    line-height: 1.1rem;
    font-size: 1rem;
  }
  .basket__totals {
    padding: 0 1rem;
    font-size: 1.1rem;
    background: none;
    border: 0;
    margin: 0;
    border-bottom: 2px solid darken($subtle-element-bg-color, 5%);
  }
  .basket__buttons {
    display: flex;
  }
  .basket__button {
    border-radius: 0;
    text-align: center;
    font-size: 1.1rem;
    border-bottom: 2px solid darken($subtle-element-bg-color, 5%);
  }
}
