.badge-pop {
  border-radius: 50px;
  border: 3px solid #fff;
  background: #000;
  color: #fff;
  opacity: 0;
  top: -100%;
  max-height: 0;
  padding: 0;
  overflow: hidden;
  margin-top: -6px;
  transition: all 0.4s ease-in-out;
  position: relative;
  display: flex;

  &.is-active {
    opacity: 1;
    max-height: 90px;
    height: auto;
    margin-top: 0;
    margin-bottom: 10px;
  }

  &:hover {
    .badge-pop__dismiss {
      opacity: 1;
    }
  }

  > a {
    display: block;
    color: #fff;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.badge-pop__eyebrow {
  padding: 10px 10px 0 0;
  text-transform: uppercase;
  &:after {
    content: "";
    flex-basis: 100%;
    height: 0;
  }
}

.badge-pop__name {
  padding: 0 10px 10px 0;
  font-size: 1.5rem;
}

.badge-pop__icon {
  margin: 1rem;

  > img {
    width: 70px;
  }
}

.badge-pop__dismiss {
  position: absolute;
  right: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border: 0;
  background: #fff;
  border-radius: 100%;
  transition: all 0.4s ease-in-out;
  opacity: 0;

  > span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  &:after {
    content: "\00d7";
    font-size: 2rem;
  }
}
