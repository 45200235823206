.footer {
  margin: 6rem auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  clear: both;

  @media (min-width: $breakpoint-lg) {
    background-color: $footer-bg-color;
  }
}

.footer__logo > img {
  height: 120px;

  @media (min-width: $breakpoint-lg) {
    height: 80px;
  }
}

.footer__blurb p {
  font-size: 1rem;
}

.footer__social-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: $breakpoint-lg) {
    order: 2;
    margin-left: auto;
  }
}

.footer__social-item {
  > a {
    color: #000;
    position: relative;
    font-size: 1.4rem;
    width: 58px;
    height: 65px;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: "\2B22";
      position: absolute;
      color: $footer-faint-fg-color;
      font-size: 4rem;
      top: 0;
      left: 0;
      line-height: 1em;
      z-index: -2;
    }
    &:after {
      content: "\2B22";
      position: absolute;
      color: #fff;
      font-size: 3.8rem;
      top: 0.1rem;
      left: 0.1rem;
      line-height: 1em;
      z-index: -2;
    }
  }
  > a > span {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}

.footer__copyright {
  margin: 2rem 1rem 4rem 1rem;
  color: $footer-faint-fg-color;

  @media (min-width: $breakpoint-lg) {
    margin: 0;
  }
}

.footer__sections {
  text-align: left;
  display: flex;
  flex-direction: column;
  max-width: $constrain-small-at;
  padding: 20px $small-margins;
  width: 100%;
  box-sizing: border-box;

  @media (min-width: $breakpoint-lg) {
    max-width: $constrain-body-at;
    flex-direction: row;
    border-bottom: 2px solid $footer-bottom-border-color;
  }
}

.footer__section {
  border-top: 1px solid $subtle-element-bg-color;
  margin-bottom: 20px;
  width: 100%;

  > h3 {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;
    margin-top: 20px;
    cursor: pointer;

    &:after {
      color: #aaa;
      position: absolute;
      margin: auto;
      border-style: solid;
      border-width: 0.15em 0.15em 0 0;
      content: "";
      height: 0.3em;
      vertical-align: top;
      width: 0.3em;
      transform: rotate(135deg);
      right: 0;
    }
  }
  &.is-active > h3 {
    &:after {
      transform: rotate(315deg);
    }
  }
  > div {
    display: none;
    margin-top: 30px;
  }
  &.is-active > div {
    display: block;
  }

  @media (min-width: $breakpoint-lg) {
    border-top: 0;

    > h3 {
      border-bottom: 2px solid $subtle-element-bg-color;
      display: inline-block;
      padding: 10px 0;
      margin: 0;

      &:after {
        display: none;
      }
      cursor: default;
    }

    > div {
      display: block;
      margin-top: 10px;
    }
  }
}

.footer__section--info {
  border-top: 0;
  text-align: center;
  margin-bottom: 1.5rem;

  @media (min-width: $breakpoint-lg) {
    text-align: left;
  }
}

.footer__fact {
  display: flex;
  color: black;

  > div {
    margin-bottom: 1.5rem;
  }

  > div:nth-child(1) {
    width: 50px;
    font-size: 1.4rem;
    display: flex;
    margin-top: 1rem;
    justify-content: center;
  }

  h4 {
    font-weight: bold;
    font-size: 1.2rem;
    margin: 0.3rem 0 0 0;
  }

  span {
    font-size: 1.1rem;
    display: block;
  }

  @media (min-width: $breakpoint-lg) {
    > div {
      margin-bottom: 0;
      margin-top: 10px;
    }
    h4 {
      font-size: 0.9rem;
    }

    span {
      font-size: 0.8rem;
    }
  }
}

.footer-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;

  a {
    color: black;
    text-decoration: none;
    display: block;
    padding: 10px 0;
  }
}

.footer__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
    justify-content: space-between;
    max-width: $constrain-body-at;
    width: 100%;
  }
}
