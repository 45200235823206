.tool-bar {
  background-color: $filters-bg-color;
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 0 1.5rem;

  .tool-bar__item {
    margin-right: 1.5rem;
  }

  @media (min-width: $breakpoint-lg) {
    padding: 0 calc((100% - #{$constrain-body-at}) / 2);

    .tool-bar__item {
      margin-right: 1.5rem;
    }
  }
}

.tool-bar__item {
  flex: 1;
  max-width: 200px;
}

.tool-bar__item--right {
  margin-left: auto;
}

.tool-bar--sticky {
  position: sticky;
  top: 0;
  z-index: 100;
}
