$font-family: "Proza Libre", sans-serif;

$constrain-max-width: 1280px;
$constrain-body-at: 1280px;
$constrain-small-at: 800px;
$breakpoint-md: 800px;
$breakpoint-lg: 1200px;
$baseline: 1.5;
$small-margins: 30px;
$large-margins: 135px;

// Colours
$expo-yellow: #fba831;

$gallery: #edecec;
$tundora: #444;
$silver: #bcbcbc;
$alto: #dbdbdb;
$dove-gray: #696969;
$serenade: #fff6e7;
$mercury: #e4e4e4;
$mirage: #181827;
$mine-shaft: #343434;
$onahau: #c5faff;
$silver-chalice: #a9a9a9;
$snowy-mint: #c5ffce;
$australian-mint: #f8ffc5;
$tuft-bush: #ffd9c5;
$torch-red: #ff191c;
$blue-ribbon: #056dfb;
$rhino: #273657;

// Colour Usage
$secondary-information-bg-color: $gallery;
$secondary-information-fg-color: $tundora;
$link-text-color: $blue-ribbon;
$base-text-color: $dove-gray;
$subtle-element-bg-color: $mercury;
$active-state-bg: $blue-ribbon;

$unread-notification-bg: $torch-red;

$typography-highlighted-element-color: $expo-yellow;

$area-individual-account-bg-color: $expo-yellow;
$area-exhibitor-account-bg-color: $rhino;

$dark-bg-color: $mirage;
$dark-divider-color: $tundora;

$positive-bg-color: $snowy-mint;
$advisory-bg-color: $serenade;
$negative-bg-color: $tuft-bush;
$neutral-bg-color: $gallery;

$meta-text-fg-color: $dove-gray;

// Layout
$aside-bg-color: $serenade;
$footer-bg-color: $gallery;
$footer-bottom-border-color: $silver-chalice;

// Header
$header-border-color: $alto;
$header-countdown-bg-color: $expo-yellow;

// Page Header
$page-header-bg-color: $serenade;

// Notifications
$notification-body-fg-color: $tundora;

// Desktop Menu
$desktop-menu-submenu-fg-color: $tundora;
$desktop-menu-divider-color: $alto;

// Mobile Menu
$mobile-menu-bg-color: $gallery;
$mobile-menu-fg-color: black;
$mobile-menu-divider-color: $silver;

// Search
$search-bg-color: $gallery;

// Forms
$form-border-radius: 10px;
$form-required-label-color: $torch-red;
$form-label-fg-color: $tundora;
$form-error-bg-color: $tuft-bush;
$form-error-fg-color: $torch-red;
$form-help-button-bg-color: $silver-chalice;
$form-help-button-fg-color: white;
$form-placeholder-fg-color: $silver;
$form-input-border-inactive-color: $silver;
$form-input-border-active-color: $mine-shaft;
$form-input-checked-fg-color: $expo-yellow;

// Buttons
$button-border-radius: 10px;
$button-primary-bg-color: $expo-yellow;
$button-primary-fg-color: black;
$button-primary-border-color: $expo-yellow;
$button-secondary-bg-color: white;
$button-secondary-fg-color: black;
$button-secondary-border-color: $expo-yellow;
$button-tertiary-bg-color: white;
$button-tertiary-fg-color: $blue-ribbon;
$button-tertiary-border-color: $blue-ribbon;
$button-negative-bg-color: white;
$button-negative-fg-color: $torch-red;
$button-negative-border-color: $torch-red;
$button-sub-action-bg-color: $gallery;
$button-sub-action-fg-color: black;
$button-inactive-bg-color: darken($mercury, 10%);
$button-inactive-fg-color: darken($mercury, 40%);
$button-stat-active-bg-color: lighten($snowy-mint, 7%);
$button-stat-active-fg-color: darken($snowy-mint, 60%);

// Footer
$footer-faint-fg-color: $tundora;

// FAQ
$faq-toggle-bg-color: $mercury;

// Paginator
$paginator-inactive-bg-color: $gallery;
$paginator-active-bg-color: white;
$paginator-border-color: $alto;
$paginator-fg-color: $tundora;

// Switch
$switch-inactive-bg-color: $alto;
$switch-active-bg-color: white;
$switch-inactive-fg-color: $tundora;
$switch-active-fg-color: $tundora;

// Filters
$filters-bg-color: $gallery;
$filters-divider-color: $alto;

// Tabs
$tabs-inactive-bg-color: $mercury;
$tabs-active-bg-color: white;
$tabs-container-border-color: $expo-yellow;
