.block-button {
  display: flex;
  justify-content: center;
}

.block-button--left {
  justify-content: flex-start;
}

.block-button--right {
  justify-content: flex-end;
}
