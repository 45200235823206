.block-contacts {
}

.block-contacts__list {
  padding-top: 60px;
}

.block-contact-item {
  background-color: $secondary-information-bg-color;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.block-contact-item__image {
  position: relative;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  width: 104px;
  height: 114px;
  margin: -60px auto 0 auto;
  background: $expo-yellow;

  > img {
    clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
    width: 100px;
    height: 110px;
    margin-left: 2px;
    margin-top: 2px;
  }
}

.block-contact-item__name {
  text-align: center;
  margin-top: 5px;
  font-size: 1.2rem;
  font-weight: 500;
}

.block-contact-item__role {
  text-transform: uppercase;
  text-align: center;
  color: $secondary-information-fg-color;
  font-size: 0.8rem;
}

.block-contact-item__text {
  font-size: 0.9em;
  flex: 1;
  color: $secondary-information-fg-color;
  margin: 5px auto 0 auto;
  width: 80%;

  p {
    color: inherit;
  }
}

.block-contact-item__link {
  text-align: center;
  margin-top: 10px;
}
