.pdf-access-pass {
  page-break-after: always;
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin: 50px;
  box-sizing: border-box;
}

.pdf-access-pass__logo {
  height: 80px;
  margin-right: 60px;
}

.pdf-access-pass__title {
  font-size: 2.5em;
  border-bottom: 5px solid #000;
  display: flex;
  align-items: center;
  height: 15%;
  margin-top: -50px;
  padding-top: 80px;
}

.pdf-access-pass__detail {
  height: 70%;
}

.pdf-access-pass__footer {
  height: 15%;
  > p {
    color: #000;
  }
}

.pdf-access-pass__detail-title {
  font-size: 3em;
  text-transform: uppercase;
  text-align: center;
}

.pdf-access-pass__detail-body {
  font-size: 4em;
  color: red;
  text-align: center;
  line-height: 1em;
}

.pdf-access-pass__location-note {
  font-size: 1em;
}

.pdf-access-pass__display-note {
  text-align: center;
  font-size: 1em;
  color: red !important;
  text-transform: uppercase;
  font-weight: bold;
}
