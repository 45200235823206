.icon-stat-line {
  list-style: none;
  margin: 0;
  padding: 0;
}

.icon-stat-line__item {
  display: inline;
  margin-right: 1rem;
  color: $secondary-information-fg-color;

  > svg {
    margin-right: 0.5rem;
  }
}
