.block-callout {
  max-width: $constrain-body-at;
  margin: 0 auto;
  padding: $small-margins;
  position: relative;
  text-align: center;
  clear: both;

  @media (min-width: $breakpoint-lg) {
    display: flex;
    text-align: left;
  }
}

.block-callout--goodtogo {
  @extend .block-callout;
  background-color: $positive-bg-color;

  &:before {
    content: "";
    display: block;
    width: 180px;
    height: 150px;
    background-image: url("../img/good-to-go.png");
    background-size: contain;
    background-repeat: no-repeat;
    margin: auto;

    @media (min-width: $breakpoint-lg) {
      margin: 0 20px 0 0;
    }
  }
}
