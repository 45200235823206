.toggle-box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding: 1.5rem;
    margin: 1.5rem 0;

    @media (min-width: $breakpoint-lg) {
        background-color: $subtle-element-bg-color;
        flex-direction: row;
    }
}

.toggle-box__label {
    flex: 1;
    color: darken($subtle-element-bg-color, 50%);
    font-weight: 500;
    margin-bottom: 10px;

    @media (min-width: $breakpoint-lg) {
        margin-bottom: 0;
    }
}

.toggle-box__items {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 4;
    width: 100%;
    justify-content: center;

    @media (min-width: $breakpoint-lg) {
        justify-content: flex-start;
    }
}

.toggle-box__item {
    flex-grow: 1;
    flex-basis: 0;
    display: block;
    border: 2px solid $subtle-element-bg-color;
    border-radius: 10px;
    max-width: 200px;
    margin: 0 5px;

    @media (min-width: $breakpoint-lg) {
        margin: 0;
        max-width: 100%;
    }
}

.toggle-box__input {
    label {
        font-weight: bold;
        color: #000;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        &:before {
            background-color: #fff;
            width: 28px;
            height: 28px;
            font-size: 1.2rem;
            margin: 0 0 10px 0;
        }

        @media (min-width: $breakpoint-lg) {
            padding: 0;
            flex-direction: row;
            &:before {
                margin: 0 10px 0 0;
            }
        }
    }

    @media (min-width: $breakpoint-lg) {
        label {
            font-size: 1.6rem;
        }
    }
}
