.wizard {
    display: flex;
    counter-reset: type-list;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
}

.wizard__step {
    counter-increment: type-list;
    position: relative;
    flex: 1;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;

    &:before {
        content: counter(type-list);
        cursor: pointer;
        font-weight: bold;
        font-size: 1.8rem;
        margin: 10px auto;
        display: block;
        text-align: center;
        background-image: url("../img/wizard-bg-inactive.svg");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        width: 80px;
        height: 80px;
        line-height: 80px;
        z-index: 10;
        position: relative;
    }

    &:after {
        content: "";
        z-index: 0;
        position: absolute;
        background-color: $subtle-element-bg-color;
        height: 5px;
        width: 100%;
        left: 0;
        right: 0;
        margin: auto;
        top: 50px;
    }

    &:first-child:after {
        width: 50%;
        left: 50%;
    }

    &:last-child:after {
        width: 50%;
        right: 50%;
    }

    &.is-active:before {
        background-image: url("../img/wizard-bg-active.svg");
    }
}

.wizard__page.is-enabled {
    display: none;

    &.is-active {
        display: block;
    }
}
