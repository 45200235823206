.search-overlay {
    background-color: $search-bg-color;
    margin: 0;
    position: fixed;
    top: 60px;
    z-index: 100;
    width: 100%;
    box-sizing: border-box;
    transform: translateY(-101%);
    transition: transform 0.2s ease-in-out;

    @media (min-width: $breakpoint-lg) {
        position: absolute;
        width: 400px;
        top: 168px;
        padding: 10px;
        right: calc((100vw - #{$constrain-max-width}) / 2);
        border-top: 3px solid darken($subtle-element-bg-color, 10%);
    }

    @media (max-width: $constrain-max-width) {
        right: 0;
    }

    &.is-active {
        transform: translateY(0);
    }
}

.search-overlay__input {
    padding: 20px 20px 20px 40px;
    display: flex;
    font-size: 1.7rem;
    align-items: center;

    > input {
        background-color: transparent;
        border: none;
        font-size: 1.2rem;
        padding: 10px;
        width: auto;
        flex: 1;
    }

    @media (min-width: $breakpoint-lg) {
        padding: 5px 10px;
        border-radius: $form-border-radius;
        border: 1px solid darken($subtle-element-bg-color, 10%);
        background-color: #fff;
        font-size: 1.5rem;
        width: 100%;

        > input {
            font-size: 1.1rem;
        }
    }
}
.search-overlay__button {
    font-size: 1.1rem;
    margin-left: 10px;
    width: auto;
    border: 0;
    background: transparent;
}

.search-overlay__results {
    list-style: none;
    margin: 0;
    padding: 0 60px;

    @media (min-width: $breakpoint-lg) {
        padding: 10px;
    }
}

.search-overlay__item a {
    font-weight: bold;
    font-size: 1.2rem;
    text-decoration: none;
    padding-bottom: 20px;
    display: block;

    > span {
        display: block;
        font-size: 0.9rem;
        font-weight: normal;
        color: #000;
    }
}
