.accordion__section {
  overflow: hidden;
  border-bottom: 1px solid $subtle-element-bg-color;
}
.accordion__title {
  font-size: 1.2rem;
  border: 0;
  background: transparent;
  width: 100%;
  text-align: left;
  position: relative;
  padding: 20px 30px 20px 0;
  cursor: pointer;
  box-sizing: border-box;

  &:after {
    content: "+";
    display: block;
    position: absolute;
    border-radius: 100%;
    background-color: $faq-toggle-bg-color;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    top: 15px;
    right: 0;
  }
}

.accordion__body {
  display: none;
  margin-top: -20px;
}

.accordion__section.is-active {
  .accordion__title:after {
    content: "-";
  }

  .accordion__body {
    display: block;
  }
}
