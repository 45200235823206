.card-event {
    text-align: center;
    display: flex;
    flex-direction: column;

    @media (min-width: $breakpoint-lg) {
        text-align: left;
    }
}

.card-event__head {
    position: relative;
}

.card-event__image {
    max-width: 410px;
    width: 100%;
    text-align: center;
    margin: auto;
    min-height: 150px;
    overflow: hidden;

    img {
        height: auto;
        display: block;
        margin: auto;
        z-index: 10;
    }

    > a,
    > div {
        background-size: 0;
        display: block;
        position: relative;
        min-height: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:before {
            content: "";
            position: absolute;
            top: -10%;
            left: -10%;
            width: 120%;
            height: 120%;
            filter: blur(0.5rem) opacity(0.2);
            background-image: inherit;
            background-size: cover;
            background-position: center center;
            z-index: 0;
            background-color: transparent;
        }
    }

    &--icon {
        @extend .card-event__image;
        overflow: hidden;
        position: relative;
        display: flex;
        align-items: center;

        > svg {
            width: 120%;
            height: 120%;
            position: absolute;
            top: -10%;
            left: -10%;
            transform: rotate(-12deg);
        }
    }
}

.card-event__tag {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    background-color: $subtle-element-bg-color;
    font-size: 0.8em;
    border-radius: $button-border-radius;
    padding: 0.1rem 1rem;
    z-index: 20;
}

.card-event__tags {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    z-index: 20;
    display: flex;
    flex-wrap: wrap;

    > div {
        background-color: $subtle-element-bg-color;
        font-size: 0.8em;
        border-radius: $button-border-radius;
        padding: 0.1rem 1rem;
        margin: 0 0.2rem 0.2rem 0;
    }
}

.card-event__header-price {
    position: absolute;
    bottom: 0;
    right: 0;
    background-color: black;
    color: #fff;
    font-size: 1.4em;
    padding: 0.3rem 1rem;
    font-weight: bold;
    z-index: 20;
}

.card-event__body {
    background-color: $subtle-element-bg-color;
    overflow: hidden;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.card-event__title {
    font-size: 1.3rem;
    margin: 1.5rem 1rem;
    line-height: 1.8rem;
    flex: 1;

    > a {
        color: black;
        text-decoration: none;
    }
}

.card-event__subtitle {
    font-size: 0.8rem;
    line-height: 1.4em;
    margin: -1rem 1rem 1rem 1rem;
}

.card-event__intro {
    flex: 1;
    margin: 1rem;
}

.card-event__stats {
    list-style: none;
    margin: 0 1rem 1rem 1rem;
    padding: 0;
    display: flex;
    font-size: 0.8rem;
    justify-content: space-between;
    align-items: flex-end;
    align-content: flex-end;
    flex: 1;
    flex-wrap: wrap;

    > li {
        padding-top: 0.5rem;
        text-align: left;
    }

    > li svg {
        margin-right: 5px;
    }
}

.card-event__description {
    margin: 0 1rem;
    flex: 1;

    > p {
        margin: 0;
    }
}

.card-event__quantity {
    margin: 1rem;
    display: flex;
    align-items: center;
}

.card-event__price {
    font-size: 1.2rem;
    font-weight: 500;
}

.card-event__more {
    border-radius: 0;
    text-decoration: none;
    max-width: 100%;
}

.card-event__notice {
    background-color: #fff;
    font-size: 0.8rem;
    padding: 0.2rem 1rem;
    margin: 1rem;
    text-align: center;
    color: $form-error-fg-color;
}
