.vertical-calendar {
  list-style: none;
  margin: 0;
  padding: 1rem;
}

.vertical-calendar__time {
  text-align: right;
  color: #999;
  padding: 1rem 0;
  font-size: 0.8rem;

  > span {
    background-color: #fff;
    padding: 0 0 0 1rem;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #999;
    margin-top: -12px;
  }
}

.vertical-calendar__list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.vertical-calendar__slot {
  &.is-hidden {
    display: none;
  }
}
