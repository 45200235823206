.stepbystep-list {
    counter-reset: type-list;
    list-style: none;
    padding: 0;

    > li {
        counter-increment: type-list;
        padding-left: 25px;
        position: relative;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5rem;
        margin: 1rem 0 1rem 0;
        position: relative;

        &:before {
            content: counter(type-list);
            position: absolute;
            top: 80px;
            right: 20px;
            color: darken($secondary-information-bg-color, 5%);
            font-size: 12rem;
            font-weight: bold;
        }
    }
}

.stepbystep-block {
    display: flex;
    flex-direction: column;
    background: $secondary-information-bg-color;
    color: $secondary-information-fg-color;
    border-radius: $button-border-radius;
    padding: 1.5rem;
    align-items: center;
    text-align: center;
}

.stepbystep-block__image > img {
    width: 100px;
}

.stepbystep-block__title {
    font-size: 1.5rem;
    z-index: 10;
}

.stepbystep-block__text {
    margin: 1.5rem 0;
    flex: 1;
    z-index: 10;
}
