.card-exhibitor {
  text-align: center;
}

.card-exhibitor__logo {
  display: block;
  height: 70px;
  > img {
    max-height: 100%;
    width: auto;
  }
}

.card-exhibitor__placeholder {
  display: block;
  color: $subtle-element-bg-color;
  font-size: 40px;
  height: 70px;
}
