@keyframes highlight {
  0% {
    background: transparent;
  }
  50% {
    background: $expo-yellow;
  }
  100% {
    background: transparent;
  }
}

.anim-highlight-on-load {
  animation: highlight 1s;
}
