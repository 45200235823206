.card-product {
}

.card-product__body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.card-product__image {
  flex: 1;
  display: flex;
  align-items: center;
}

.card-product__title {
  font-size: 1.2rem;
  padding: 0.5rem 0;
  margin: 0;
}

.card-product__price {
  font-size: 1.2rem;
}
