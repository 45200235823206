.modal {
  position: fixed;
  height: 50%;
  width: 90%;
  background-color: #fff;
  left: 5%;
  top: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: all 0.2s ease-in-out;
  transform: translateY(-200%);
  padding: 1rem;
  box-sizing: border-box;
  text-align: center;

  @media (min-width: $breakpoint-lg) {
    width: 60%;
    left: 20%;
  }

  &.is-active {
    transform: translateY(0);
  }
}
