.tabbed-container__tabs {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;

  > li {
    display: block;
    flex: 1;
    background-color: $tabs-inactive-bg-color;
    border: 1px solid #fff;
    border-bottom: 0;
    text-align: center;
    padding: 1.5rem 1rem;
    font-size: 1.2rem;
    font-weight: bold;
    cursor: pointer;

    &.is-active {
      background-color: $tabs-active-bg-color;
      border-color: $tabs-container-border-color;
      margin-bottom: -1px;
    }
  }
}

.tabbed-container__body {
  border: 1px solid $tabs-container-border-color;
}

.tabbed-container.is-active {
  .tabbed-container__tabs {
    display: flex;
  }
  .tabbed-container__body {
    > div {
      display: none;
      &.is-active {
        display: block;
      }
    }
  }
}
