h1 {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 1.5rem 0 0 0;
  line-height: 1em;
}

h2 {
  font-size: 1.5rem;
  font-weight: 400;
  margin: 1.5rem 0 0 0;
  line-height: 1em;
}

h3 {
  font-size: 1.3rem;
  font-weight: 400;
  margin: 1.5rem 0 0 0;
  line-height: 1em;
}

h4 {
  font-size: 1.1rem;
  font-weight: 400;
  margin: 1rem 0 0 0;
  line-height: 1em;
}

h5 {
  font-size: 1rem;
  font-weight: 400;
  margin: 0.5rem 0 0 0;
  line-height: 1em;
}

h6 {
  font-size: 1rem;
  font-weight: 600;
  margin: 0.5rem 0 0 0;
  line-height: 1em;
}

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin: 1.5rem 0 0 0;
  color: $base-text-color;
  word-break: break-word;
}

.rich-text {
  margin: 0;

  p {
    margin: 1.5rem 0 1.5rem 0;
  }

  ul,
  ol {
    counter-reset: type-list;
    list-style: none;
    padding: 0;

    > li {
      counter-increment: type-list;
      padding-left: 25px;
      position: relative;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5rem;
      margin: 1rem 0 1rem 0;
      color: $base-text-color;

      &:before {
        content: "●";
        position: absolute;
        top: 0;
        left: 0;
        color: $typography-highlighted-element-color;
        font-size: 1.3rem;
      }
    }
  }

  ol > li:before {
    content: counter(type-list);
    font-weight: bold;
    font-size: 1.2rem;
  }

  .media {
    padding-bottom: 56.25%;
    height: 0;
    position: relative;

    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  .image.image-style-align-center {
    max-width: 50%;
    margin: auto;
  }
  .image.image-style-align-left {
    max-width: 50%;
    float: left;
  }
  .image.image-style-align-right {
    max-width: 50%;
    float: right;
  }

  figure {
    margin: 1.5rem 0;

    img,
    iframe {
      max-width: 100%;
    }
  }
}

hr {
  border-top: 1px solid $subtle-element-bg-color;
  border-bottom: 0;
  margin: 20px 0;
}

blockquote {
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 2rem;
  margin: 1.5rem 0 1.5rem 0;
  font-style: italic;
  color: $base-text-color;
  position: relative;

  &:before {
    position: absolute;
    content: "“";
    top: 3rem;
    left: -1rem;
    font-size: 10rem;
    font-style: normal;
    opacity: 0.1;
    pointer-events: none;
  }
}

a {
  color: $blue-ribbon;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.typography-text--very-big {
  font-size: 1.8rem;
}

.typography-text--big {
  font-size: 1.4rem;
}

.typography-text--small {
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.typography-text--compact {
  margin: 0.5rem 0 0.5rem 0;
}

.typography-text--tiny {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5rem;
}

.typography-text--center {
  text-align: center;
}

.typography-text--light {
  color: #fff;
}

.typography-text--price {
  color: $base-text-color;
  font-weight: bold;
  text-align: right;
}

.typography-text--status {
  font-size: 0.9rem;
  text-transform: uppercase;
  display: block;

  &-positive {
    @extend .typography-text--status;
    color: darken($positive-bg-color, 50%);
  }

  &-negative {
    @extend .typography-text--status;
    color: darken($negative-bg-color, 50%);
  }

  &-neutral {
    @extend .typography-text--status;
    color: darken($neutral-bg-color, 50%);
  }
}

.typography-header {
  font-size: 2.4rem;
  text-align: center;
}

.typography-header--section {
  font-size: 1.8rem;
  margin: 20px 0 20px 0;
}

.typography-header--alt {
  text-transform: uppercase;
  text-align: center;
}

.typography-tabular-header {
  border: 1px solid $subtle-element-bg-color;
  border-width: 1px 0 1px 0;
  margin: 1.5rem 0 0 0;
  padding: 10px 0;
  color: $base-text-color;
  font-size: 1rem;
  font-weight: bold;
}
