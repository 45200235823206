.flex-grid__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style: none;
    border: 0;
    flex-wrap: wrap;

    &--heading {
        @extend .flex-grid__row;
        font-weight: bold;
        color: $base-text-color;
        padding: 10px 0;
    }

    &--border-top,
    &--border-bottom,
    &--border-top-bottom {
        @extend .flex-grid__row;
        border-style: solid;
        border-color: $subtle-element-bg-color;
        border-top-width: 1px;
        margin-top: 1.5rem;
    }

    &--border-bottom {
        border-top-width: 0;
        border-bottom-width: 1px;
        margin-top: 0;
    }

    &--border-top-bottom {
        border-bottom-width: 1px;
    }
}

.flex-grid__col {
    flex: 1;
}

.flex-grid__col--right {
    flex: 0;
    margin-left: auto;
    text-align: right;
}

.flex-grid__col--narrow {
    flex: 0.2;
}

.flex-grid--padded {
    .flex-grid__row {
        padding: 10px 0;
    }

    .flex-grid__col {
        padding-right: 1.5rem;
    }

    .flex-grid__col:last-child {
        @media (min-width: $breakpoint-lg) {
            padding-right: 0;
        }
    }
}
