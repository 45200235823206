.section {
    padding: 1.5rem;
    background-size: 0;
    position: relative;
    overflow: hidden;
    z-index: 20;
    clear: both;

    @media (min-width: $breakpoint-lg) {
        padding: 2rem 6rem;
        margin: 1.5rem 0;
    }

    img {
        max-width: 70vw;
        height: auto;
    }

    &--centered {
        text-align: center;
    }
}

.section__columns {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    max-width: $constrain-body-at;
    margin: 0 auto;
    padding: 0 $small-margins;

    > div {
        flex: 1;
    }

    > div:nth-child(1) {
        margin-right: 2rem;
    }
}

.section__column {
    &--centered {
        text-align: center;
    }
}

.section__title {
    font-size: 2.2rem;
    font-weight: 500;
    margin: 0 0 2rem 0;
}

.section__text {
    font-size: 1.2rem;

    p {
        color: #333;
        font-size: 1.2rem;
    }
}

.section__body {
    &--right {
        text-align: right;
    }
    &--left {
        text-align: left;
    }
    &--center {
        text-align: center;
    }
}

.section__cta > a {
    font-size: 1.2rem;
    font-weight: 500;
    margin: 1rem 0;
    display: block;
}

// Specialised Sections

.section--intro {
    background-color: $subtle-element-bg-color;
}
