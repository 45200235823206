.block-sponsor {
  margin: 0 1.5rem;
  padding: 1.5rem 1.5rem;
  background-size: 0;
  position: relative;
  overflow: hidden;
  z-index: 20;
  background-color: $subtle-element-bg-color;
  clear: both;
  box-sizing: border-box;
  border: 1px solid $expo-yellow;
  text-align: center;
  color: darken($base-text-color, 20%);
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-lg) {
    text-align: left;
    margin: auto;
    padding: 3rem 1.5rem;
    margin-bottom: 1.5rem;
  }
}

.block-sponsor__tag {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.block-sponsor__logo {
  @media (min-width: $breakpoint-lg) {
    position: absolute;
    right: 1.5rem;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
  }
}

.block-sponsor__title {
  font-size: 2.2rem;
  font-weight: 200;
  margin: 0 0 1rem 0;
}

.block-sponsor__blurb {
  min-width: 200px;
  margin-top: 1.5rem;

  @media (min-width: $breakpoint-lg) {
    margin-top: 0;
    margin-right: 450px;
  }
}
