.block-announcements {
}

.block-announcements__title {
  background: $aside-bg-color;
  margin: 0;
  padding: 12px;
  font-size: 1.2rem;
}

.block-announcements__entries {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    border: 4px solid $aside-bg-color;
    padding: 22px;
    border-top: none;
  }

  > li > h3 {
    font-size: 1.1rem;
    margin: 0;
  }
}
