.block-action {
  padding: 3rem 1.5rem 5rem 1.5rem;
  background-size: 0;
  position: relative;
  overflow: hidden;
  z-index: 20;
  background-color: $subtle-element-bg-color;
  clear: both;
  text-align: center;

  @media (min-width: $breakpoint-lg) {
    padding: 4rem 6rem;
    margin: 1.5rem 0;
  }
}

.block-action__image {
  text-align: center;
  flex: 1;
}

.block-action__image > img {
  filter: drop-shadow(3px 3px 5px rgba(0, 0, 0, 0.4));
}

.block-action__body {
  min-width: 200px;
  margin-top: 1.5rem;
  flex: 2;

  @media (min-width: $breakpoint-lg) {
    margin-top: 0;
  }
}

.block-action__title {
  font-size: 2.2rem;
  font-weight: 500;
  margin: 0 0 2rem 0;
}

.block-action__text {
  font-size: 1.2rem;
}

.block-action__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.block-action__link {
  font-weight: 500;
  display: block;
  margin-top: 2rem;
  font-size: 1.2rem;

  @media (min-width: $breakpoint-lg) {
    flex: 1;
  }
}

.block-action__columns {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

// Alignments
.block-action--left {
  @extend .block-action;

  @media (min-width: $breakpoint-lg) {
    text-align: left;

    .block-action__image {
      margin-right: 2rem;
    }
  }
}

.block-action--right {
  @extend .block-action;
  @media (min-width: $breakpoint-lg) {
    text-align: right;

    .block-action__image {
      order: 2;
      margin-left: 2rem;
    }
  }
}

.block-action--center {
  @extend .block-action;
  text-align: center;

  .block-action__image {
    margin-bottom: 1.5rem;
  }

  .block-action__columns {
    flex-direction: column;
  }
}

// Styles
.block-action--simple {
  padding: 1rem 6rem;
  background-color: #fff;
}

.block-action--cta {
  @extend .block-action;
  text-align: center;
  background-color: #fff;

  .block-action__link {
    text-align: center;
    color: #000;
    margin-right: auto;
    margin-left: auto;
  }

  .block-action__link.button--primary {
    color: #000;
  }
}

.block-action--bg {
  text-shadow: 1px 1px 2px rgba(255, 255, 255, 0);

  .block-action__title,
  .block-action__text,
  .block-action__text .rich-text p,
  .block-action__link {
    color: #fff;
  }

  > * {
    z-index: 10;
    position: relative;
  }

  &:before {
    content: "";
    position: absolute;
    background-color: #000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: inherit;
    background-size: cover;
    background-position: top center;
    z-index: 2;
    opacity: 0.5;
    display: block;
  }

  .button--subtle {
    color: #fff;
  }
}

.block-action--split {
  padding: 0;
  text-align: left;

  .block-action__columns {
    background-color: $expo-yellow;
    padding: 0;
    align-items: stretch;
  }

  .block-action__title {
    font-size: 1.5rem;
    margin: 0;
  }

  .block-action__body {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    flex: 1;

    p {
      color: black;
    }
  }

  .block-action__text {
    flex: 1;
  }

  .block-action__image {
    margin: 0;
    max-width: 50%;
    overflow: hidden;

    > img {
      filter: none;
      vertical-align: middle;
    }
  }

  .block-action__links {
    justify-content: flex-start;
  }
}
