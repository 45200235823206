.stand-category {
  display: flex;
  align-items: center;
  margin: 2px 10px 2px 0;
  color: $secondary-information-fg-color;
  position: relative;

  &:before {
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  &--board-games {
    &:before {
      background-image: url("../img/categories/board-games.svg");
    }
  }

  &--card-games {
    &:before {
      background-image: url("../img/categories/card-games.svg");
    }
  }

  &--roleplaying-games {
    &:before {
      background-image: url("../img/categories/roleplaying-games.svg");
    }
  }

  &--games-retailer {
    &:before {
      background-image: url("../img/categories/games-retailer.svg");
    }
  }

  &--historical-miniatures {
    &:before {
      background-image: url("../img/categories/historical-miniatures.svg");
    }
  }

  &--sci-fifantasy-miniatures {
    &:before {
      background-image: url("../img/categories/scifi-fantasy.svg");
    }
  }

  &--games-publisher {
    &:before {
      background-image: url("../img/categories/games-publisher.svg");
    }
  }

  &--artistauthor {
    &:before {
      background-image: url("../img/categories/artist-comics.svg");
    }
  }

  &--manufacturing-services {
    &:before {
      background-image: url("../img/categories/games-publisher.svg");
    }
  }

  &--family-games {
    &:before {
      background-image: url("../img/categories/family-games.svg");
    }
  }

  &--party-games {
    &:before {
      background-image: url("../img/categories/party-games.svg");
    }
  }

  &--clothingjewellery {
    &:before {
      background-image: url("../img/categories/clothing-jewellery.svg");
    }
  }

  &--games-accessories {
    &:before {
      background-image: url("../img/categories/games-accessories.svg");
    }
  }

  &--icon-only {
    margin: 0;
    &:before {
      margin: 0;
    }
  }

  > .stand-category__tooltip {
    display: none;
  }

  &:hover .stand-category__tooltip {
    display: block;
  }
}

.stand-category__tooltip {
  position: absolute;
  top: 20px;
  left: 0;
  background-color: #f7f7f7;
  width: auto;
  padding: 10px;
  box-shadow: #ddd 2px 2px 0;
  text-align: center;
  z-index: 20;
  font-size: 0.8rem;
  min-width: 100px;
}
