.media {
  display: flex;
}

.media--sm-break {
  flex-direction: column;
  @media (min-width: $breakpoint-lg) {
    flex-direction: row;
  }
}

.media__icon {
  font-size: 1.4rem;
  padding: 20px 20px 0 0;
}

.media__body {
  > p:nth-of-type(1) {
    margin: 0.5rem 0 0 0;
  }
}
