@tailwind base;
@tailwind components;
@tailwind utilities;

@import "variables.scss";

html {
    height: 100%;
    line-height: $baseline;
    font-size: 16px;
}

html,
body {
    width: 100%;
}

img {
    margin: auto;
}

body {
    background-color: #fff;
    font-family: $font-family;
    width: 100%;
    height: 100%;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.wagtail-userbar {
    display: none !important;
    left: 2rem !important;
}

.responsive-object {
    position: relative;
}

.responsive-object iframe,
.responsive-object object,
.responsive-object embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@import "mods.scss";
@import "animations.scss";

@import "components/typography.scss";
@import "components/notification.scss";
@import "components/layout.scss";
@import "components/header.scss";
@import "components/footer.scss";
@import "components/nav-primary.scss";
@import "components/nav-mobile.scss";
@import "components/nav-vertical.scss";
@import "components/button.scss";
@import "components/form.scss";
@import "components/page-header.scss";
@import "components/search-overlay.scss";
@import "components/block-text.scss";
@import "components/block-button.scss";
@import "components/block-faq.scss";
@import "components/block-image.scss";
@import "components/block-embed.scss";
@import "components/block-action.scss";
@import "components/block-sponsor.scss";
@import "components/block-card-grid.scss";
@import "components/block-callout.scss";
@import "components/block-announcements.scss";
@import "components/block-contacts.scss";
@import "components/card-grid.scss";
@import "components/card-news.scss";
@import "components/card-event.scss";
@import "components/card-product.scss";
@import "components/card-exhibitor.scss";
@import "components/card.scss";
@import "components/paginator.scss";
@import "components/content-filters.scss";
@import "components/tool.scss";
@import "components/simple-table.scss";
@import "components/wizard.scss";
@import "components/media.scss";
@import "components/accordion.scss";
@import "components/product.scss";
@import "components/slider.scss";
@import "components/search-result.scss";
@import "components/ordered-list.scss";
@import "components/basket.scss";
@import "components/modal.scss";
@import "components/note.scss";
@import "components/breadcrumbs.scss";
@import "components/meta-box.scss";
@import "components/tool-bar.scss";
@import "components/meta-item.scss";
@import "components/toggle-box.scss";
@import "components/checkbox-grid.scss";
@import "components/flex-grid.scss";
@import "components/empty-state.scss";
@import "components/logo-list.scss";
@import "components/hijack.scss";
@import "components/expo-map.scss";
@import "components/app-tiles.scss";
@import "components/badge.scss";
@import "components/hijack.scss";
@import "components/section.scss";
@import "components/trophies.scss";
@import "components/hex-stack.scss";
@import "components/pwyw.scss";
@import "components/icon-link.scss";
@import "components/stand-category.scss";
@import "components/vertical-list.scss";
@import "components/event-line.scss";
@import "components/tabbed-container.scss";
@import "components/vertical-calendar.scss";
@import "components/responsive-embed.scss";
@import "components/badge-pop.scss";
@import "components/switch.scss";
@import "components/stepbystep-block.scss";
@import "components/award-finalists.scss";
@import "components/alert-bar.scss";
@import "components/tooltip.scss";
@import "components/pdf-access-pass.scss";
@import "components/icon-stat-line.scss";
@import "components/pwa-banner.scss";
@import "components/community-map.scss";
@import "components/react-select.scss";
@import "layout/content-grid.scss";
@import "layout/page-exhibitor.scss";

@layer components {
    @import "tw-components/form.css";
    @import "tw-components/raffle.css";
}
