.breadcrumbs > ol {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  font-weight: 500;
  flex-wrap: wrap;
  justify-content: center;

  @media (min-width: $breakpoint-lg) {
    justify-content: flex-start;
  }

  > li {
    text-align: center;
  }

  > li a {
    text-decoration: none;
    color: black;

    &:after {
      content: ">";
      margin: 0 10px;
    }
  }

  > li {
    &:last-child a:after {
      margin: 0;
      content: "";
    }
  }
}
