.event-line {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-decoration: none;
  color: #000;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
}

.event-line.is-inactive {
  opacity: 0.5;
}

.event-line.is-hidden {
  display: none;
}

.event-line__image {
  margin-right: 1rem;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  width: 50px;
  background: #000;

  &:before {
    content: "";
    display: block;
    width: 50px;
    height: 50px;
    background-image: url("../img/grey-hex-transparent.png");
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.event-line__body {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
}

.event-line__eyebrow {
  font-size: 0.7rem;
  color: $base-text-color;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;

  > ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;

    > li {
      display: flex;
      align-items: center;
    }

    > li:after {
      content: "●";
      font-size: 0.4rem;
      margin: 0 0.5rem;
    }

    > li:last-child():after {
      content: "";
    }
  }
}

.event-line__name {
  font-weight: bold;
  font-size: 1.1rem;
  flex: auto;
  margin-right: 20px;
  margin-bottom: 0;
}

.event-line__short-description,
.event-line__full-description {
  font-size: 0.9rem;
  margin-right: 20px;
  color: $base-text-color;
  width: 100%;

  p {
    font-size: 0.9rem;
    margin: 0.5rem 0;
  }
}

.event-line__badges {
  flex: auto;
  display: flex;
  width: 100%;
  margin-bottom: 10px;

  @media (min-width: $breakpoint-lg) {
    width: auto;
    margin-bottom: 0;
    justify-content: flex-end;
  }
}

.event-line__badge {
  background-color: $subtle-element-bg-color;
  border-radius: 15px;
  text-align: center;
  padding: 4px 10px;
  font-size: 0.8rem;
  margin-right: 10px;

  &--flat {
    font-size: 0.8rem;
    background: transparent;
    border-radius: 0;
    padding: 0;
  }
}

.event-line__actions {
  margin: 10px 0;
  font-size: 0.9rem;
}

.event-line__more-info-toggle {
  padding: 0 10px 0 0;
}

.event-line--small {
  padding: 0;
  .event-line__badges {
    @media (min-width: $breakpoint-lg) {
      width: 100%;
      justify-content: flex-start;
      margin-bottom: 10px;
    }
  }
}
