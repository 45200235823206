.simple-table {
  border-collapse: collapse;
  width: 100%;
  color: $base-text-color;

  th,
  tfoot td {
    border: 1px solid $subtle-element-bg-color;
    border-width: 1px 0 1px 0;
    margin: 0;
    padding: 10px 0;
  }

  th {
    text-align: left;
  }

  td {
    padding: 0;
    text-align: left;
  }
}

.simple-table__column--slim {
  width: 100px;
}

.simple-table__column--actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  > * {
    display: block;
    padding-right: 10px;
  }
}

.simple-table__priority-text {
  font-weight: bold;
}

.simple-table__secondary-text {
  font-size: 0.9rem;
  display: block;
}

.simple-table--padded {
  @extend .simple-table;
  td {
    padding: 10px 10px 10px 0;
  }
}
