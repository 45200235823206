.note {
  margin: 1.5rem -1.5rem;
  padding: 1.5rem 30px;
  position: relative;

  > p {
    margin: auto;
    color: black;
  }
}

.note--positive,
.note--success {
  @extend .note;
  background: $positive-bg-color;
}

.note--advisory,
.note--warning {
  @extend .note;
  background: $advisory-bg-color;
}

.note--negative,
.note--error {
  @extend .note;
  background: $negative-bg-color;
}

.note--neutral,
.note--info {
  @extend .note;
  background: $neutral-bg-color;
}
