.app-tile-list {
    display: grid;
    grid-gap: 1rem;
    padding: 0;
    margin: 1rem;
    grid-template-columns: 1fr 1fr;
}

.app-tile-list__title {
    margin: 0;
    padding: 1rem 30px;
    background: linear-gradient(
        180deg,
        $expo-yellow 0,
        lighten($expo-yellow, 10%) 90%,
        $expo-yellow 100%
    );
}

.app-tile {
    flex: 1;
    min-width: 30%;
    color: #fff;
    text-align: center;
    border-radius: 8px;
    display: flex;
    align-items: center;

    > a {
        border-radius: 8px;
        position: relative;
        overflow: hidden;
        min-height: 130px;
        width: 100%;
        display: flex;
        flex-direction: column;
        color: #fff;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0.5rem;
        background: $gallery;

        &:hover {
            text-decoration: none;
        }
    }
}

.app-tile__icon {
    font-size: 3rem;
    color: #444;
    z-index: 2;
    height: 70px;
}

.app-tile__icon-bg {
    font-size: 12rem;
    opacity: 0.04;
    color: #444;
    z-index: 2;
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.app-tile__title {
    font-size: 1.2rem;
    margin: 0;
    z-index: 1;
    color: #000;
    text-shadow: 1px 1px #FFF;
    display: flex;
    align-items: center;
}

.app-tile--hidden {
    display: none;
}
