.card-news {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.card-news__image {
  max-width: 340px;
  width: 100%;
  text-align: center;
  margin: auto;

  img {
    max-width: 100%;
    height: auto;
  }
}
.card-news__title {
  font-size: 1.3rem;
  line-height: 1.8rem;
  margin: 1rem 0 0 0;

  > a {
    color: black;
    text-decoration: none;
  }
}

.card-news__byline {
  color: $secondary-information-fg-color;
  text-align: center;
  font-size: 0.8rem;
  margin: 1rem 0;
}

.card-news__intro {
  flex: 1;
  margin: 0;
}

.card-news__more {
  margin: 1rem auto;
  text-decoration: none;
}
