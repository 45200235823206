.ordered-list {
  counter-reset: type-list;
  list-style: none;
  padding: 0;
  display: inline-block;
  margin: 0;

  > li {
    counter-increment: type-list;
    padding-left: 3rem;
    position: relative;
    font-size: 1rem;
    font-weight: 400;
    line-height: 2rem;
    margin: 1rem 0 1rem 0;

    &:before {
      content: counter(type-list);
      background-color: $typography-highlighted-element-color;
      border-radius: 100%;
      width: 2rem;
      height: 2rem;
      line-height: 2rem;
      position: absolute;
      top: 0;
      left: 0;
      color: black;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
    }
  }
}
