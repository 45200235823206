.logo-list {
  list-style: none;
  margin: 0 0 1.5rem 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  > li {
    margin: 0 1.5rem;
  }
}
