.content-grid {
  display: grid;
  grid-auto-rows: repeat(1fr);
  max-width: $constrain-body-at;
  margin: 0 auto;
  padding: 0 $small-margins;
  grid-column-gap: 2rem;
  grid-auto-flow: row dense;

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: 3fr 1fr;
  }
}

.content-grid__block {
  @media (min-width: $breakpoint-lg) {
    grid-column: 1;
  }
}

.content-grid__block--aside {
  @media (min-width: $breakpoint-lg) {
    grid-column: 2;
  }
}
