.block-embed {
  margin: 1.5rem (0px - $small-margins);
  text-align: center;
  width: 100%;
}

.block-embed--left {
  @extend .block-embed;

  @media (min-width: $breakpoint-lg) {
    float: left;
    max-width: 50%;
    margin: 1.5rem 20px 0 0;
  }
}

.block-embed--right {
  @extend .block-embed;

  @media (min-width: $breakpoint-lg) {
    float: right;
    max-width: 50%;
    margin: 1.5rem 0 0 20px;
  }
}

.block-embed--center {
  @extend .block-embed;
  text-align: center;
}
