.pwa-banner {
    background-color: $expo-yellow;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    border: 0;
    width: 100%;
    color: #000;

    @media (min-width: $breakpoint-md) {
        display: none;
    }

    @media (min-width: $constrain-max-width) {
        padding: 10px calc((100vw - #{$constrain-max-width}) / 2);
    }

    > h2 {
        display: flex;
        margin: 0;
        padding: 0 0 5px 0;

        > svg {
            margin-right: 10px;
        }
    }

    > p {
        margin: 0;
        color: #000;
        font-size: 0.8rem;
        line-height: 1rem;
    }
}
