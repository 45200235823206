.trophies {
  margin-top: 1rem;
  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    display: grid;
  }
}

.trophy {
  border: 4px solid #eee;
  padding: 20px;
  margin-left: -4px;
  margin-top: -4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  color: #999;
  overflow: hidden;
}

.trophy__name {
  font-size: 1.2rem;
  font-weight: 500;
}

.trophy__description {
  font-size: 0.9rem;
}

.trophy__date {
  font-size: 0.9rem;
  font-style: italic;
}

.trophy__image {
  filter: grayscale(100%) opacity(50%);
}

.trophy--completed {
  color: #000;
  .trophy__image {
    filter: grayscale(0%) opacity(100%) drop-shadow(0 0 10px $expo-yellow);
  }
}
