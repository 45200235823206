.tool-bar {
    display: flex;

    > .tool-bar__buttons {
        display: none;
        margin: 1.5rem 0;
    }

    @media (min-width: $breakpoint-lg) {
        flex-direction: column;
        > .tool-bar__buttons {
            display: block;
        }
    }
}

.tool__button {
    text-transform: uppercase;
    border: 0;
    background: transparent;
    font-weight: bold;
    padding: 20px 0;
    width: 100%;
    text-align: left;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: #000;
    box-sizing: border-box;

    &:focus {
        outline: 1px dotted $form-input-border-inactive-color;
    }

    > svg {
        margin-left: 10px;
    }

    @media (min-width: $breakpoint-lg) {
        cursor: default;

        &:focus {
            outline: 0;
        }
    }
}

.tool__button--link {
    cursor: pointer;
    @extend .tool__button;
    > svg {
        margin-left: 0;
        margin-right: 10px;
    }
}

.tool__button--sort {
    @extend .tool__button;
}

.tool__button--filter {
    @extend .tool__button;
}

.tool__button--filter svg:nth-child(2) {
    display: none;
}

.is-active {
    .tool__body {
        display: block;
    }

    @media (max-width: $breakpoint-lg) {
        .tool__button--sort svg {
            transform: rotate(180deg);
        }

        .tool__button--filter svg:nth-child(2) {
            display: block;
        }

        .tool__button--filter svg:nth-child(1) {
            display: none;
        }
    }
}

.tool__body {
    display: none;
    background-color: $filters-bg-color;
    position: absolute;
    top: 58px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 99;
    padding: 0 1.5rem 1.5rem 1.5rem;

    @media (min-width: $breakpoint-lg) {
        display: block;
        position: relative;
        top: 0;
        padding: 0;

        > .tool-bar__buttons {
            display: none;
        }
    }
}

.tool__columns {
    display: flex;
    flex-wrap: wrap;

    @media (min-width: $breakpoint-lg) {
        flex-direction: column;
    }
}

.tool__column {
    flex: 1;
    min-width: 260px;
    margin-right: 40px;

    @media (min-width: $breakpoint-lg) {
        margin-right: 0;
    }
}

.tool__buttons {
    width: 100%;
    margin-top: 20px;
    padding: 0;
    box-sizing: border-box;
}

.tool-body-state {
    color: $base-text-color;
    display: flex;
    padding: 10px 0;
    max-width: 800px;
}

.tool-body-state__count {
    flex: 1;
}

.tool-body-state__actions {
    > button {
        border: 0;
        background: transparent;
        color: $link-text-color;
    }
}

/* Always Toggleable Variant
   Usually a tool is toggleable on mobile and fixed on large displays
   This variant will make it always in a toggleable 'mobile' form
*/
.tool--always-toggleable {
    @media (min-width: $breakpoint-lg) {
        position: relative;
        .tool__button {
            cursor: pointer;
        }
        .tool__body {
            display: none;
            position: absolute;
            top: 58px;
            max-width: 400px;
            width: 400px;
            padding: 0 1.5rem 1.5rem 1.5rem;

            > .tool-bar__buttons {
                display: none;
            }
        }
    }

    &.is-active .tool__body {
        display: block;
    }
}
