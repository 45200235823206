.notification {
    padding: 20px 40px;
    position: relative;
    transition: all 0.2s ease-in-out;
    z-index: 0;

    &.is-dismissed {
        height: 0;
        padding: 0;
        overflow: hidden;
    }
}

.notification__header {
    font-size: 1.3rem;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0;
}

.notification__body {
    margin: 0 auto;
    font-size: 1rem;
    color: $notification-body-fg-color;
    max-width: $constrain-body-at;
}

.notification__dismiss {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
    border: 0;
    background: transparent;

    > span {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    &:after {
        content: "\00d7";
        font-size: 2rem;
    }
}

.notification--positive,
.notification--success {
    @extend .notification;
    background: $positive-bg-color;
}

.notification--advisory,
.notification--warning {
    @extend .notification;
    background: $advisory-bg-color;
}

.notification--negative,
.notification--error {
    @extend .notification;
    background: $negative-bg-color;
}

.notification--neutral,
.notification--info {
    @extend .notification;
    background: $neutral-bg-color;
}
