.community-map-popup {
    display: flex;
    flex-direction: column;
    align-items: start;
    min-width: 200px;
}

.community-map-popup__title {
    font-size: 1.1rem;
    font-weight: bold;
}

.community-map-popup__type {
    background-color: $subtle-element-bg-color;
    font-size: 0.8em;
    border-radius: $button-border-radius;
    padding: 0.1rem 1rem;
    z-index: 20;
}

.community-map-popup__description {
    margin: 10px 0;
}

.community-map-popup__location,
.community-map-popup__date {
    margin-top: 10px;
    display: flex;
    svg {
        margin-right: 5px;
    }
}

.community-map-popup__link {
    padding: 10px 0;
    font-weight: bold;
    color: black;
    display: flex;
    margin-left: auto;
}
