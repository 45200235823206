.tooltip {
  position: relative;
  border-bottom: 1px dashed darken($secondary-information-bg-color, 10%);

  &:hover:before,
  &:hover:after {
    display: block;
    visibility: visible;
    opacity: 1;
  }
}

.tooltip:before {
  content: attr(alt);
  position: absolute;

  top: 50%;
  transform: translateY(-50%);
  left: 100%;
  margin-left: 15px;
  width: 200px;
  padding: 10px;
  border-radius: 10px;
  background: darken($secondary-information-bg-color, 10%);
  color: $secondary-information-fg-color;
  text-align: center;
  z-index: 1000;
  opacity: 0;
  transition: 0.3s opacity;
  visibility: hidden;
}
.tooltip:after {
  opacity: 0;
  transition: 0.3s opacity;
  content: "";
  position: absolute;

  /* position tooltip correctly */
  left: 100%;
  margin-left: -5px;

  /* vertically center */
  top: 50%;
  transform: translateY(-50%);

  /* the arrow */
  border: 10px solid darken($secondary-information-bg-color, 10%);
  border-color: transparent darken($secondary-information-bg-color, 10%)
    transparent transparent;
  visibility: hidden;
}
