/* Nav Primary
-------------------------
Main menu with megamenu structure
*/

// Desktop menu with 'megamenu' style dropdowns
.nav-primary-desktop {
  height: 100%;
  display: none;

  @media (min-width: $breakpoint-lg) {
    display: block;
  }

  > ul {
    height: 100%;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

// JS adds the 'is-js' class to the nav, which overrides
// hover behaviour with JS, so we can dismiss the menu on navigation
.nav-primary-desktop.is-js {
  .nav-primary-desktop__item--dropdown {
    &:hover > a {
      &:after {
        bottom: 30px;
      }
      &:before {
        bottom: -20px;
      }
    }

    &.is-active > a {
      &:after {
        bottom: -30px !important;
      }
      &:before {
        bottom: 0 !important;
      }
    }
  }
  .nav-primary-desktop__item > .nav-primary-desktop__submenu {
    transform: scale3d(1, 0, 1);
  }
  .nav-primary-desktop__item.is-active > .nav-primary-desktop__submenu {
    transform: scale3d(1, 1, 1);
  }
}

.nav-primary-desktop__item {
  height: 100%;

  > .nav-primary-desktop__submenu {
    transition: transform 0.2s ease-in-out;
    transform: scale3d(1, 0, 1);
    transform-origin: top left;
  }

  &:hover > .nav-primary-desktop__submenu,
  &.is-active > .nav-primary-desktop__submenu {
    transform: scale3d(1, 1, 1);
  }

  > a {
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
    box-sizing: border-box;
    padding: 20px 19px;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    font-weight: 600;
    text-align: center;
  }

  &--dropdown {
    @extend .nav-primary-desktop__item;

    > a {
      &:after {
        color: #aaa;
        position: absolute;
        margin: auto;
        border-style: solid;
        border-width: 0.15em 0.15em 0 0;
        content: "";
        height: 0.3em;
        vertical-align: top;
        width: 0.3em;
        transform: rotate(135deg);
        bottom: 30px;
        left: 0;
        right: 0;
        transition: all 0.2s ease-in-out;
      }

      &:before {
        position: absolute;
        content: "";
        width: 0;
        height: 0;
        left: 0;
        right: 0;
        margin: auto;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 15px solid #eee;
        border-top: 0;
        bottom: -20px;
        transform: rotate(0deg);
        transition: all 0.2s ease-in-out;
        z-index: 20;
      }
    }
    h3 {
      font-size: 1rem;
      color: #000;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    &:hover > a,
    &.is-active > a {
      &:after {
        bottom: -30px;
      }
      &:before {
        bottom: 0px;
      }
    }
  }
}

.nav-primary-desktop__submenu {
  position: absolute;
  top: 110px;
  width: 100vw;
  left: 0;
  padding: 0 20px 20px 20px;
  background-color: #eee;
  display: grid;
  box-sizing: border-box;
  transition: opacity 0.3s ease-in-out;
  border-top: 1px solid $header-border-color;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(min-content, max-content);

  @media (min-width: $constrain-max-width) {
    left: calc(-1 * (100vw - #{$constrain-max-width}) / 2);
    padding: 0 calc((100vw - #{$constrain-max-width}) / 2);
    padding-bottom: 20px;
  }
}

.nav-primary-desktop__column {
  flex: 1;
  color: $desktop-menu-submenu-fg-color;
  font-size: 0.9rem;

  > h2 {
    text-transform: uppercase;
    font-size: 1rem;
    padding: 5px 5px 10px 0;
    margin-bottom: 15px;
    width: auto;
    border-bottom: 2px solid $desktop-menu-divider-color;
    display: inline-block;
    font-weight: bold;

    > a {
      color: $desktop-menu-submenu-fg-color;
    }
  }

  p {
    margin: 0 0 1rem 0;
    color: $desktop-menu-submenu-fg-color;
    font-size: 0.9rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    > li > a {
      text-decoration: none;
      color: $desktop-menu-submenu-fg-color;
      padding: 3px 5px 2px 0;
      display: block;
    }
  }
}
