.alert-bar {
  background-color: $advisory-bg-color;
  padding: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: center;
  justify-content: center;

  @media (min-width: $constrain-max-width) {
    padding: 10px calc((100vw - #{$constrain-max-width}) / 2);
  }

  > p {
    margin: 0;
  }

  > div {
    margin-left: 20px;
  }
}
