.content-filters__title {
    padding: 10px 0;
    color: black;
    text-transform: uppercase;
}

.content-filters__linklist {
    list-style: none;
    margin: 0;
    padding: 0;

    > li a {
        display: block;
        padding: 10px 0;
        color: $base-text-color;
        text-decoration: none;
    }
}

.content-filters__range {
    margin: 0 0.5rem 2rem 0.5rem;
    .noUi-horizontal {
        height: 10px;
    }
    .noUi-target {
        border: 0;
        box-shadow: none;
        background: darken($subtle-element-bg-color, 10%);
    }
    .noUi-connect {
        background: #fff;
    }
    .noUi-horizontal .noUi-tooltip {
        bottom: -90%;
        background: transparent;
        border: 0;
        font-size: 0.8rem;
        color: $base-text-color;
    }
    .noUi-horizontal .noUi-handle {
        border: 0;
        background: transparent;
        box-shadow: none;

        &:before {
            content: none;
        }
        &:after {
            content: "";
            width: 20px;
            height: 20px;
            border-radius: 50% 50% 50% 0;
            background: $expo-yellow;
            transform: rotate(-45deg);
            margin: -5px 0 0 -10px;
            box-shadow: none;
            border: 0;
        }
    }
}

.content-filters__checklist {
    list-style: none;
    margin: 0;
    padding: 0;
}

.content-filter__checkbox {
    padding: 0;
    color: $base-text-color;

    > label {
        position: relative;
        display: flex;
        align-items: center;
        padding: 7px 0;

        &:after {
            content: "";
            box-sizing: content-box;
            position: absolute;
            right: 1px;
            top: auto;
            bottom: auto;
            width: 20px;
            height: 20px;
            border: 1px solid $form-input-border-inactive-color;
            background: white;
        }
    }

    > input {
        position: absolute;
        top: auto;
        overflow: hidden;
        clip: rect(1px, 1px, 1px, 1px);
        width: 1px;
        height: 1px;
        white-space: nowrap;
    }

    > input:checked + label:after {
        content: "\2713";
        text-align: center;
        right: 0;
        width: 19px;
        height: 19px;
        border: 2px solid $form-input-border-active-color;
    }

    > input:focus + label {
        outline: 1px dotted $form-input-border-inactive-color;
    }
}

.content-filter__icon {
    margin: 0 5px 0 0 !important;
}
