.meta-item {
    color: $meta-text-fg-color;
}

.meta-item__title {
    display: inline;
    font-weight: bold;

    &:after {
        content: ":";
    }
}

.meta-item__image {
    float: left;
    margin-right: 10px;
}

.meta-item__content {
    display: inline;
}

.meta-item__content--block {
    display: block;
}
