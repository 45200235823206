.meta-box {
    background-color: $filters-bg-color;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 1.5rem;

    @media (min-width: $breakpoint-lg) {
        order: 2;
        margin: 1.5rem 0;
        border-radius: $form-border-radius;
        padding: 1.5rem 0;
    }
}

.meta-box__header {
    background-color: darken($filters-bg-color, 10%);
    margin: -1.5rem -1.5rem 1.5rem -1.5rem;
    padding: 1.5rem;
    text-transform: uppercase;
    text-align: center;
    font-size: 1.2rem;
    color: darken($meta-text-fg-color, 10%);

    @media (min-width: $breakpoint-lg) {
        border-radius: $form-border-radius $form-border-radius 0 0;
    }
}

.meta-box--toolbar {
    flex-direction: row;
    padding: 0;

    .meta-box__item {
        margin: 0 1.5rem;
    }

    @media (min-width: $breakpoint-lg) {
        flex-direction: column;

        .meta-box__item {
            margin: 0;
        }
    }
}

.meta-box__item {
    flex: 1;
    max-width: 200px;

    &--fullsize {
        max-width: 100%;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    @media (min-width: $breakpoint-lg) {
        box-sizing: content-box;
        max-width: 300px;
        width: 300px;
        margin-bottom: 1rem;
        padding: 0 1.5rem 1rem 1.5rem;
        position: relative;
        flex: 0;

        &:after {
            content: "";
            position: absolute;
            height: 1px;
            background-color: $filters-divider-color;
            width: calc(100% - #{$small-margins} * 2);
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        &:nth-last-of-type(1) {
            margin-bottom: 0;

            &:after {
                content: none;
            }
        }
    }
}

.meta-box__meta-item {
    padding: 0.5rem 0;
    font-size: 1rem;

    @media (min-width: $breakpoint-lg) {
        font-size: 1.2rem;
    }
}
